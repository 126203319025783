import { dealersApiConnector } from '@/api/dealers';
import { firstValueFrom } from 'rxjs';
import { sortBy } from 'lodash';
import type { Dealers, Sales } from '@/models';
import type { DealersApi } from '@/api/dealers';
import type { OptionItem } from '@/models/picker';

const dealersApi$: DealersApi = dealersApiConnector();

export const fetchSales = () => {
  return firstValueFrom(dealersApi$.getSales());
};

export const fetchDealers = (salesUserName: string) => {
  return firstValueFrom(dealersApi$.getDealers(salesUserName));
};

export function mapSalesToOptionItems(sales: Sales[]): OptionItem[] {
  const options = sales.map(s => ({
    value: s,
    label: `${s.firstname} ${s.lastname}`,
  }));

  return sortBy(options, o => o.label);
}

export function mapDealersToOptionItems(dealers: Dealers[]): OptionItem[] {
  const options = dealers.map(dealer => ({
    value: dealer,
    label: dealer.name ?? '',
  }));

  return sortBy(options, o => o.label);
}
