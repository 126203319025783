import { Button, Icon } from '@sgme/ui';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import type { Color } from '@sgme/ui';
import type { FC } from 'react';
import type { IntlKey } from '@/locales';
import type { Purpose } from '@/models';

interface ModalFooterProps {
  cancelLabelKey?: IntlKey;
  confirmLabelKey?: IntlKey;
  cancelDisabled?: boolean;
  confirmDisabled?: boolean;
  confirmHidden?: boolean;
  confirmationPurpose?: Purpose;
  confirmationIcon?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  loading?: boolean;
  color?: Color;
}

export const ModalFooter: FC<ModalFooterProps> = ({
  cancelLabelKey = 'Cancel',
  confirmLabelKey = 'Confirm',
  cancelDisabled,
  confirmDisabled,
  confirmHidden,
  confirmationPurpose,
  confirmationIcon,
  onCancel,
  onConfirm,
  loading = false,
  color = 'primary',
}) => {
  return (
    <div className="modal-footer">
      <Button
        type="button"
        purpose="flat"
        color="secondary"
        size="lg"
        onClick={onCancel}
        disabled={loading || cancelDisabled}
      >
        <FormattedMessage id={cancelLabelKey} />
      </Button>

      {!confirmHidden && (
        <Button
          type="button"
          purpose={confirmationPurpose ?? 'primary'}
          color={color}
          size="lg"
          onClick={onConfirm}
          disabled={loading || confirmDisabled}
          loading={loading}
          className={classNames({ 'pe-3': confirmationPurpose })}
        >
          <FormattedMessage id={confirmLabelKey} />
          {confirmationIcon && <Icon icon={confirmationIcon} outlined size="sm" className="ps-2" />}
        </Button>
      )}
    </div>
  );
};
