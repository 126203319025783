import { FormattedNumber as FormatNumber } from 'react-intl';
import { Fragment } from 'react';
import type { ComponentPropsWithRef, ElementType } from 'react';

type FormattedNumberProps<T extends ElementType> = {
  as?: T;
  value?: number;
  unit?: string;
} & ComponentPropsWithRef<T>;

export function FormattedNumber<T extends ElementType>({ as, value, unit = '', ...props }: FormattedNumberProps<T>) {
  const Component = as ?? Fragment;
  const appendUnit = unit === '%' ? unit : ' '.concat(unit);
  return (
    <Component {...props}>
      {value ? (
        <>
          <FormatNumber value={value} />
          {appendUnit}
        </>
      ) : (
        '-'
      )}
    </Component>
  );
}
