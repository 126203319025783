import { AppMain } from '@/components/AppMain';
import { AppNav, NavActions, NavButtonWithIcon, NavHeader, NavTab, NavTabs, NavTitle } from '@/components/AppNav';
import { AuctionItem } from '@/components/AuctionItem';
import { DateRangePicker } from '@/components/shared/DatesRangePicker';
import { EmptyState } from '@/components/shared/EmptyState';
import { ErrorState } from '@/components/shared/ErrorState';
import { FoldableCardLoadingSkeleton } from '@/components/shared/FoldableCard';
import { FormattedMessage } from 'react-intl';
import { FormattedText } from '@/components/shared/FormattedText';
import {
  currentAuctionsRequested,
  getBlotterAuctions,
  leaveCurrentAuctionPage as leaveCurrentAuctionPage,
} from '@/store/auctions';
import {
  getCancelModalIsOpened,
  getOverrideModalIsOpened,
  getPdcDetailsModalIsOpened,
  getUiState,
  uiAuctionStartTimeRangeChanged,
  uiNewAuctionClicked,
} from '@/store/ui';
import { hasPermission } from '@/store/users';
import { useDispatch, useSelector } from 'react-redux';
import CancelModal from '@/components/Modal/CancelModal';
import OverrideModal from '@/components/Modal/OverrideModal';
import PdcDetailsModal from '@/components/Modal/Pdc/PdcDetailsModal';
import React, { useEffect } from 'react';
import type { Auction } from '@/models';
import type { DateRange } from '@/models/dateRange';
import type { ErrorResponse } from '@/models/Responses/ErrorResponse';
import type { FC } from 'react';

const AuctionList: FC<{
  isLoading: boolean;
  auctions?: Auction[];
  error?: ErrorResponse;
}> = ({ isLoading, auctions, error }) => {
  if (isLoading) {
    return <FoldableCardLoadingSkeleton className="mt-3" count={5} />;
  }

  if (error) {
    return (
      <ErrorState
        id="current-auctions-error-state"
        my={48}
        originMessage={JSON.stringify(error)}
        showContactUsButton
        title="Unable to retrieve auctions"
      />
    );
  }

  return auctions === undefined || auctions.length === 0 ? (
    <EmptyState
      id="current-auctions-empty-state"
      title="No auction found"
      messages={['To find your auctions,', 'search with the filter above.']}
    />
  ) : (
    <div className="mt-4">
      {auctions.map(auction => (
        <AuctionItem key={auction.auctionUuid} className="mb-4" auction={auction} />
      ))}
    </div>
  );
};

export const CurrentPage: FC = () => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector(getUiState('current'));
  const { isLoading, auctions, error } = useSelector(getBlotterAuctions);
  const canOpenCreationForm = useSelector(hasPermission('READ_AUCTION_DETAILS'));
  const canSeeCurrentAuctions = useSelector(hasPermission('READ_CURRENT_AUCTIONS'));
  const canSeeAuctionHistory = useSelector(hasPermission('READ_AUCTION_HISTORY'));

  const overrideModalIsOpened = useSelector(getOverrideModalIsOpened());
  const cancelModalIsOpened = useSelector(getCancelModalIsOpened());
  const pdcDetailsModalIsOpened = useSelector(getPdcDetailsModalIsOpened());

  useEffect(() => {
    dispatch(currentAuctionsRequested());
    return () => {
      dispatch(leaveCurrentAuctionPage());
    };
  }, [dispatch]);

  const onDateFilterChanged = React.useCallback(
    (dateRange: DateRange) => {
      dispatch(uiAuctionStartTimeRangeChanged('current', dateRange));
    },
    [dispatch],
  );

  const count = auctions ? auctions.length : '-';

  return (
    <>
      <AppNav>
        <NavHeader withTabs>
          <FormattedText id="Bidding" as={NavTitle} />
          <NavActions>
            {canOpenCreationForm && (
              <FormattedText
                id="New auction"
                as={NavButtonWithIcon}
                purpose="primary"
                color="primary"
                icon="add"
                to="/auctions/create"
                onClick={() => dispatch(uiNewAuctionClicked())}
              />
            )}
          </NavActions>
        </NavHeader>
        <NavTabs>
          {canSeeCurrentAuctions && (
            <NavTab to="/auctions/current">
              <FormattedMessage id="In progress" />
              <span className="badge rounded-pill text-bg-light ms-1">{count}</span>
            </NavTab>
          )}
          {canSeeAuctionHistory && <FormattedText id="History" as={NavTab} to="/auctions/history" />}
        </NavTabs>
      </AppNav>
      <AppMain>
        <DateRangePicker
          label="Auction start time"
          className="w-25"
          dateRange={{ startDate, endDate }}
          onChange={onDateFilterChanged}
          hideClearButton={true}
          // minValue={new Date()} To add back later
        />
        <AuctionList isLoading={isLoading} error={error} auctions={auctions} />
      </AppMain>
      {overrideModalIsOpened && <OverrideModal initialDisplayed={true} />}
      {cancelModalIsOpened && <CancelModal initialDisplayed={true} />}
      {pdcDetailsModalIsOpened && <PdcDetailsModal initialDisplayed={true} />}
    </>
  );
};
