import { Card, CardBody, CardHeader, CardTitle } from '@/components/shared/Card';
import { DatePicker } from '@/components/shared/DatePicker';
import { FormLoadingSkeleton } from '@/components/shared/FormLoadingSkeleton';
import { FormattedMessage } from 'react-intl';
import { NumberInput } from '@/components/shared/NumberInput';
import { TextInput } from '@/components/shared/TextInput';
import { TimePicker } from '@/components/shared/TimePicker';
import { ToggleButtons } from '@/components/shared/ToggleButtons';
import { addDaysToIsoDateString, applyTimeToDate, getTimeFromDate, toTimestamp } from '@/utils/date';
import { en } from '@/locales/en';
import { getAuctionsStatus } from '@/store/auctions';
import { getColumnSpanClass } from '@/components/FormBuilder/FormBuilder.helper';
import { getUiState, uiAuctionStartDateUpdated, uiStaticFieldChanged } from '@/store/ui';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import classNames from 'classnames';
import type { FC } from 'react';
import type { PickerState } from '@sg-bootstrap/components';
import type { ToggleButtonItem } from '@/components/shared/ToggleButtons';

interface GlobalInformationBlockProps {
  className?: string;
  page: 'create' | 'edit';
}

export const GlobalInformationBlock: FC<GlobalInformationBlockProps> = ({ className, page }) => {
  const dispatch = useDispatch();

  const {
    StartTime: startDateTime,
    EndTime: endDateTime,
    PositionConfirmTime: positionConfirmationDateTime,
    PreNotificationTime: preNotificatioNDateTime,
    Strategy,
    FileCode,
    HedgeIdDef,
    Advised,
  } = useSelector(getUiState(page));

  const startTime = getTimeFromDate(startDateTime);
  const startDate = startDateTime;

  const endTime = getTimeFromDate(endDateTime);
  const endDate = endDateTime;

  const positionConfirmationTime = getTimeFromDate(positionConfirmationDateTime);
  const isAuctionStartTimeNotSelected = startDateTime === undefined;

  const preNotificatioNTime = getTimeFromDate(preNotificatioNDateTime);

  const auctionTimeState = useMemo<PickerState | undefined>(
    () =>
      startDateTime && endDateTime && toTimestamp(startDateTime) > toTimestamp(endDateTime) ? 'invalid' : undefined,
    [startDateTime, endDateTime],
  );

  const auctionTimeValidationMessage = useMemo<string | undefined>(
    () => (auctionTimeState === 'invalid' ? en['Auction end time must be after auction start time'] : undefined),
    [auctionTimeState],
  );

  const advisedStrategyAvailableItems = [
    { label: 'No', value: false } as ToggleButtonItem<boolean>,
    { label: 'Yes', value: true } as ToggleButtonItem<boolean>,
  ];

  const shouldShowLoadingSkeleton = page === 'edit' && startDateTime === undefined;

  const onStartDateSelected = React.useCallback(
    (startDate?: string) => {
      dispatch(uiAuctionStartDateUpdated(page, startDate));
    },
    [dispatch, page],
  );

  const onStartTimeSelected = React.useCallback(
    (time?: string) => {
      dispatch(uiStaticFieldChanged(page, { StartTime: applyTimeToDate(time, startDate) }));
    },
    [dispatch, page, startDate],
  );

  const onEndDateSelected = React.useCallback(
    (endDate?: string) => {
      dispatch(uiStaticFieldChanged(page, { EndTime: applyTimeToDate(endTime ?? '00:00', endDate) }));
    },
    [dispatch, endTime, page],
  );

  const onEndTimeSelected = React.useCallback(
    (time?: string) => {
      dispatch(uiStaticFieldChanged(page, { EndTime: applyTimeToDate(time, endDate) }));
    },
    [dispatch, endDate, page],
  );

  const onPositionConfirmationTimeSelected = React.useCallback(
    (time?: string) => {
      dispatch(uiStaticFieldChanged(page, { PositionConfirmTime: applyTimeToDate(time, startDate) }));
    },
    [dispatch, page, startDate],
  );

  const onPreNotificatioNTime = React.useCallback(
    (time?: string) => {
      dispatch(
        uiStaticFieldChanged(page, {
          PreNotificationTime: applyTimeToDate(time, addDaysToIsoDateString(startDate, -1)),
        }),
      );
    },
    [dispatch, page, startDate],
  );

  const onStrategyChange = React.useCallback(
    (value?: string) => {
      dispatch(uiStaticFieldChanged(page, { Strategy: value }));
    },
    [dispatch, page],
  );

  const onFileCodeChange = React.useCallback(
    (value?: string) => {
      dispatch(uiStaticFieldChanged(page, { FileCode: value }));
    },
    [dispatch, page],
  );

  const onHedgeIdDefChange = React.useCallback(
    (value?: number) => {
      dispatch(uiStaticFieldChanged(page, { HedgeIdDef: value }));
    },
    [dispatch, page],
  );

  const onAdvisedStrategyChange = React.useCallback(
    (value: boolean) => {
      dispatch(uiStaticFieldChanged(page, { Advised: value }));
    },
    [dispatch, page],
  );

  const defaultColumnSpan = getColumnSpanClass();

  const { isLoading: isEnrichLoading } = useSelector(getAuctionsStatus('enrich'));

  return (
    <Card className={classNames('card-bordered pb-0', className)}>
      <CardHeader className="">
        <CardTitle>
          <FormattedMessage id="Global information" />
        </CardTitle>
      </CardHeader>
      <CardBody className="container-fluid">
        {shouldShowLoadingSkeleton ? (
          <FormLoadingSkeleton />
        ) : (
          <div className="row row-cols-12">
            <DatePicker
              id="StartDate"
              label="Auction start date"
              className={`mb-4 ${defaultColumnSpan}`}
              value={startDate}
              onChange={onStartDateSelected}
              minValue={new Date()}
              hideClearButton={true}
              isDisabled={isEnrichLoading}
            />
            <TimePicker
              label="Auction start time"
              className={`mb-4 ${defaultColumnSpan}`}
              value={startTime}
              onChange={onStartTimeSelected}
              isDisabled={isAuctionStartTimeNotSelected || isEnrichLoading}
              state={auctionTimeState}
              validationMessage={auctionTimeValidationMessage}
            />
            <DatePicker
              id="EndDate"
              label="Auction end date"
              className={`mb-4 ${defaultColumnSpan}`}
              value={endDate}
              onChange={onEndDateSelected}
              isDisabled={isAuctionStartTimeNotSelected || isEnrichLoading}
              minValue={startDateTime}
              hideClearButton={true}
            />
            <TimePicker
              label="Auction end time"
              className={`mb-4 ${defaultColumnSpan}`}
              value={endTime}
              onChange={onEndTimeSelected}
              isDisabled={isAuctionStartTimeNotSelected || isEnrichLoading}
              state={auctionTimeState}
              validationMessage={auctionTimeValidationMessage}
            />
            <TimePicker
              label="fieldId.PositionConfirmTime"
              className={`mb-4 ${defaultColumnSpan}`}
              value={positionConfirmationTime}
              onChange={onPositionConfirmationTimeSelected}
              isDisabled={isAuctionStartTimeNotSelected || isEnrichLoading}
            />
            <TimePicker
              label="fieldId.PreNotificationTime"
              className={`mb-4 ${defaultColumnSpan}`}
              value={preNotificatioNTime}
              onChange={onPreNotificatioNTime}
              isDisabled={isAuctionStartTimeNotSelected || isEnrichLoading}
            />
            <TextInput
              label="fieldId.Strategy"
              className={`mb-4 ${defaultColumnSpan}`}
              value={Strategy}
              onBlur={onStrategyChange}
              readOnly={isEnrichLoading}
            />
            <TextInput
              label="fieldId.FileCode"
              className={`mb-4 ${defaultColumnSpan}`}
              value={FileCode}
              onBlur={onFileCodeChange}
              maxLength={5}
              readOnly={isEnrichLoading}
            />
            <NumberInput
              label="Iddef"
              className={`mb-4 ${defaultColumnSpan}`}
              value={HedgeIdDef}
              maxLength={9}
              onBlur={onHedgeIdDefChange}
              disableDecimals={true}
              enableShortcuts={false}
              thousandSeparator=""
              readOnly={isEnrichLoading}
            />
            <ToggleButtons
              items={advisedStrategyAvailableItems}
              fieldLabel="Advised strategy?"
              className={`mb-4 ${defaultColumnSpan}`}
              onChange={onAdvisedStrategyChange}
              selectedItem={Advised as boolean}
              isDisabled={isEnrichLoading}
            />
          </div>
        )}
      </CardBody>
    </Card>
  );
};
