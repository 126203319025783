import { FieldWrapper } from '@/components/shared/FieldWrapper';
import { toPickerItems, toPickerKey } from '@/utils/picker';
import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import type { IntlKey } from '@/locales';
import type { OptionItem, OptionValue } from '@/models';
import type { SgPicker, SgPickerChangeEvent } from '@/types/WebComponents';
import type { Size } from '@sgme/ui';

export interface MultiplePickerProps<T extends OptionValue> {
  className?: string;
  e2e?: string;
  hideClearButton?: boolean;
  isInvalid?: boolean;
  items?: OptionItem[];
  label: IntlKey;
  maxDisplayedItems?: number;
  onChange?: (values: T[]) => void;
  placeholder?: IntlKey;
  size?: Size;
  values?: T[];
  icon?: string;
  disabled?: boolean;
}

export function MultiplePicker<T extends OptionValue>(props: MultiplePickerProps<T>) {
  const {
    className,
    e2e,
    hideClearButton = false,
    isInvalid = false,
    items = [],
    label,
    maxDisplayedItems = 0,
    onChange,
    placeholder = 'Select',
    size = 'md',
    values = [],
    icon,
    disabled = false,
  } = props;
  const picker = useRef<SgPicker>(null);

  const { formatMessage } = useIntl();

  const onSelectItem = useCallback(
    (event: SgPickerChangeEvent<T>) => {
      const { key, item } = event.detail;
      if (values.find(v => toPickerKey(v) === key)) {
        return;
      }
      if (onChange) {
        onChange([...values, item.value]);
      }
    },
    [values, onChange],
  );

  const onUnSelectItem = useCallback(
    (event: SgPickerChangeEvent<T>) => {
      if (onChange) {
        const selections = values.filter(v => toPickerKey(v) !== event.detail.key);
        onChange(selections.length > 0 ? selections : []);
      }
    },
    [onChange, values],
  );

  const onClearItem = useCallback(() => {
    if (onChange) {
      onChange([]);
    }
  }, [onChange]);

  useEffect(() => {
    picker.current?.setItems(toPickerItems(formatMessage, items));
    values.forEach(value => picker.current.selectItemByKey(toPickerKey(value)));
  }, [formatMessage, items, values]);

  useEffect(() => {
    const ref = picker.current;
    if (ref) {
      ref.addEventListener('selectItem', onSelectItem as EventListener);
      ref.addEventListener('unselectItem', onUnSelectItem as EventListener);
      ref.addEventListener('clear', onClearItem as EventListener);
    }
    return () => {
      if (ref) {
        ref.removeEventListener('selectItem', onSelectItem as EventListener);
        ref.removeEventListener('unselectItem', onUnSelectItem as EventListener);
        ref.removeEventListener('clear', onClearItem as EventListener);
      }
    };
  }, [picker, onSelectItem, onUnSelectItem, onClearItem]);

  return (
    <FieldWrapper label={label} className={className}>
      <div className="d-flex align-items-center">
        <sg-picker
          data-e2e={`${e2e}-picker`}
          max-displayed-items={maxDisplayedItems}
          no-clear={hideClearButton || values === undefined}
          icon={icon}
          placeholder={formatMessage({ id: placeholder })}
          ref={picker}
          size={size}
          state={isInvalid ? 'invalid' : undefined}
          truncate-badges="true"
          disabled={disabled}
          max-badges={10}
        />
      </div>
    </FieldWrapper>
  );
}
