const getUnauthorizedPage = (): string => {
  const {
    sgwtServices: { unauthorizedPage },
  } = window.appConfigs;
  return unauthorizedPage;
};

export const getUnauthorizedRedirectUrl = (): string => {
  const unauthorizedPage = getUnauthorizedPage();
  const origin = encodeURIComponent(window.location.origin);
  return `${unauthorizedPage}?redirectUri=${origin}&language=en`;
};
