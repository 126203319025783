import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { RoutePath } from '@/store/router';

interface UseRedirectParams {
  to: RoutePath;
  when: boolean;
}
export function useRedirect(params: UseRedirectParams) {
  const { when, to } = params;
  const navigate = useNavigate();

  useEffect(() => {
    if (when) {
      navigate(to);
    }
  }, [when, navigate, to]);
}
