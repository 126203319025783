import { type FC, useEffect, useState } from 'react';
import { FieldWrapper } from '@/components/shared/FieldWrapper';
import { TextInput as SgTextInput } from '@sgme/ui';
import { isDefined } from '@sgme/fp';
import classNames from 'classnames';
import type { Alignment } from '@/models/SgBootstrap';
import type { IntlKey } from '@/locales';

export interface TextInputProps {
  e2e?: string;
  className?: string;
  label?: IntlKey;
  value?: string;
  placeholder?: IntlKey | string;
  readOnly?: boolean;
  icon?: string;
  onBlur?: (value?: string) => void;
  maxLength?: number;
  textAlign?: Alignment;
}

export const TextInput: FC<TextInputProps> = ({
  e2e,
  label,
  className,
  onBlur,
  value,
  placeholder = 'Enter',
  readOnly = false,
  icon,
  maxLength,
  textAlign = 'left',
}) => {
  const [inputValue, setInputValue] = useState<typeof value>(value);

  useEffect(() => setInputValue(value), [value]);

  return isDefined(label) ? (
    <FieldWrapper label={label} className={className}>
      <SgTextInput
        className={classNames(`text-${textAlign}`)}
        data-e2e={`${e2e}-data-row-input`}
        readOnly={readOnly}
        icon={icon}
        placeholder={readOnly ? '-' : placeholder}
        value={inputValue ?? ''}
        onChange={({ target }) => setInputValue(target.value)}
        onBlur={({ target }) => onBlur && isDirty(target.value, value) && onBlur(sanitize(target.value))}
        maxLength={maxLength}
      />
    </FieldWrapper>
  ) : (
    <div className={className}>
      <SgTextInput
        className={classNames(`text-${textAlign}`)}
        data-e2e={`${e2e}-data-row-input`}
        readOnly={readOnly}
        icon={icon}
        placeholder={readOnly ? '-' : placeholder}
        value={inputValue ?? ''}
        onChange={({ target }) => setInputValue(target.value)}
        onBlur={({ target }) => onBlur && isDirty(target.value, value) && onBlur(sanitize(target.value))}
        maxLength={maxLength}
      />
    </div>
  );
};

const isDirty = (toSet: string, current?: string) => {
  return sanitize(toSet) !== sanitize(current);
};

function sanitize(value?: string): string | undefined {
  return value === '' || value === undefined ? undefined : value;
}
