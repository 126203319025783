import { en } from './en';
import type { IntlKey } from './intlKey';

export type Locale = 'en';

export type Translations = Record<IntlKey, string>;

type TranslationsPerLocale = Record<Locale, Partial<Translations>> & {
  en: Translations;
};

const translations: TranslationsPerLocale = {
  en,
};

export const DEFAULT_LOCALE: Locale = 'en';

export const getTranslations = (locale: Locale): Translations => translations[locale];

export * from './intlKey';
