import { DealersListBlock } from '@/components/DealersListBlock';
import { ErrorsBanner } from '@/components/ErrorsBanner/ErrorsBanner';
import { type FC, useMemo } from 'react';
import { GlobalInformationBlock } from '@/components/GlobalInformationBlock';
import { InstrumentBlock } from '@/components/InstrumentBlock';
import { TimeZoneBanner } from '@/components/TimeZoneBanner/TimeZoneBanner';
import { getGenericErrorMessage, getSalesInitTimeZoneId, getValidationErrors } from '@/store/auctions';
import { useGenerateValidationErrorMessages } from '@/components/EnrichForm/EnrichForm.helpers';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import type { IntlKey } from '@/locales';

export interface EnrichFormProps {
  page: 'create' | 'edit';
  readonly?: boolean;
}

export const EnrichForm: FC<EnrichFormProps> = ({ page, readonly }) => {
  const validationErrors = useSelector(getValidationErrors(page));
  const validationErrorMessages = useGenerateValidationErrorMessages(validationErrors);

  const genericErrors = useSelector(getGenericErrorMessage(page));
  const genericErrorMessages = genericErrors?.content ? [genericErrors?.content] : undefined;
  const genericErrorTitle = genericErrors?.title;
  const defaultGenericErrorTitle = useMemo<IntlKey>(() => {
    switch (page) {
      case 'create':
        return 'Unable to create auction';
      case 'edit':
        return 'Unable to save auction details';
    }
  }, [page]);

  const defaultGenericErrorContent = [
    'An unexpected error occurred. Please try again or contact us if the issue persists.' as IntlKey,
  ];

  const timeZoneId = useSelector(getSalesInitTimeZoneId);

  return (
    <div className={classNames('d-flex flex-column justify-content-center gap-3', readonly ? 'pe-none' : 'pe-auto')}>
      {genericErrors && (
        <ErrorsBanner
          errorMessages={genericErrorMessages ?? defaultGenericErrorContent}
          title={genericErrorTitle ?? defaultGenericErrorTitle}
        ></ErrorsBanner>
      )}
      {validationErrors && (
        <ErrorsBanner errorMessages={validationErrorMessages} title="Error(s) occurred"></ErrorsBanner>
      )}
      <TimeZoneBanner timeZoneId={timeZoneId} />
      <GlobalInformationBlock page={page} />
      <InstrumentBlock page={page} />
      <DealersListBlock />
    </div>
  );
};
