import { UnderlyingInput } from '@/components/UnderlyingInput';
import { getAuctionsStatus } from '@/store/auctions';
import { useFieldContext } from '@/components/FormBuilder/FieldContext';
import { useSelector } from 'react-redux';
import type { StrategyTypeEnum } from '@/models';
import type { Underlying } from '@/api';

export const UnderlyingsField = () => {
  const { fieldDefinition, values, onPatch } = useFieldContext();
  const { id, translationKey } = fieldDefinition;

  const { isLoading: isEnrichLoading } = useSelector(getAuctionsStatus('enrich'));

  return (
    <UnderlyingInput
      strategyType={values?.['InstrumentType'] as StrategyTypeEnum}
      label={`fieldId.${translationKey}`}
      underlying={values?.[id] as Underlying}
      onSelect={v => id && onPatch({ [id]: v ?? null })}
      disabled={isEnrichLoading}
    />
  );
};
