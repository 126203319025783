import { type Auction } from '@/models';
import { DropdownButton } from '@/components/shared/DropdownButton';
import { DropdownItem } from 'reactstrap';
import { type FC, useCallback, useMemo } from 'react';
import { FormattedText } from '@/components/shared/FormattedText';
import { canCancelAuctionDetails } from '@/store/auctions';
import { hasPermission } from '@/store/users';
import { openCancelModal } from '@/store/ui';
import { useCopyTextToClipboard } from '@/hooks/useCopyToClipboard/useCopyToClipboard';
import { useDispatch, useSelector } from 'react-redux';

export interface AuctionItemActionsDropdownProps {
  className?: string;
  auction: Auction;
}

export const AuctionItemActionsDropdown: FC<AuctionItemActionsDropdownProps> = ({ auction }) => {
  const dispatch = useDispatch();
  const { auctionUuid } = auction;
  const canCancelAuction = useSelector(canCancelAuctionDetails(auction));
  const hasViewTechnicalIdPermission = useSelector(hasPermission('VIEW_TECHNICAL_ID'));

  const canCopyAuctionId = useMemo(() => hasViewTechnicalIdPermission, [hasViewTechnicalIdPermission]);

  const onCancelButtonClicked = useCallback(() => {
    dispatch(openCancelModal(auctionUuid, auction?.strategy));
  }, [dispatch, auctionUuid, auction]);

  const extractAuctionIdForCopyToClipboard = useCallback(() => auctionUuid ?? '', [auctionUuid]);
  const onCopyAuctionIdClicked = useCopyTextToClipboard(
    extractAuctionIdForCopyToClipboard,
    undefined,
    undefined,
    false,
  );

  return (
    <DropdownButton color="flat-secondary" icon="more_vert" size="sm" className="ms-1" menuAlignToRight={true}>
      {canCancelAuction && (
        <DropdownItem onClick={onCancelButtonClicked}>
          <FormattedText as="span" id="Cancel auction" />
        </DropdownItem>
      )}
      {canCopyAuctionId && (
        <DropdownItem onClick={onCopyAuctionIdClicked}>
          <FormattedText as="span" id="Copy auction ID" />
        </DropdownItem>
      )}
    </DropdownButton>
  );
};
