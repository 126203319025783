import { ajax } from 'rxjs/ajax';
import { applyGetRequestMiddleware, applyRequestMiddleware } from './middlewares';
import { mapResultWithResponseMiddleware } from './httpClient.helpers';
import type { HttpCall, HttpClient, HttpConfig, HttpGetConfig } from './httpClient.models';
import type { Observable, SchedulerLike } from 'rxjs';

export const httpClient: HttpClient = {
  delete<T>(config: HttpConfig): Observable<HttpCall<T>> {
    const start = performance.now();
    const httpConfig = { ...config, method: 'DELETE' };
    const requestConfig = applyRequestMiddleware(httpConfig);

    const res$ = ajax.delete<T>(requestConfig.url, requestConfig.headers as Record<string, string>);
    return mapResultWithResponseMiddleware<T>(requestConfig, start)(res$);
  },
  getJSON<T>(config: HttpGetConfig): Observable<HttpCall<T>> {
    const start = performance.now();
    const requestConfig = applyGetRequestMiddleware(config);

    const res$ = ajax.get<T>(requestConfig.url, requestConfig.headers as Record<string, string>);
    return mapResultWithResponseMiddleware<T>(requestConfig, start)(res$);
  },
  getRAW(config: HttpGetConfig): Observable<HttpCall<string>> {
    const start = performance.now();
    const httpConfig = { ...config, method: 'GET', responseType: 'text' };
    const requestConfig = applyGetRequestMiddleware(httpConfig);

    const res$ = ajax<string>(requestConfig);
    return mapResultWithResponseMiddleware<string>(requestConfig, start)(res$);
  },
  getBlob(config: HttpGetConfig): Observable<HttpCall<Blob>> {
    const start = performance.now();
    const httpConfig = { ...config, method: 'GET', responseType: 'blob' };
    const requestConfig = applyGetRequestMiddleware(httpConfig);

    const res$ = ajax<Blob>(requestConfig);
    return mapResultWithResponseMiddleware<Blob>(requestConfig, start)(res$);
  },
  patch<T>(config: HttpConfig): Observable<HttpCall<T>> {
    const start = performance.now();
    const requestConfig = applyRequestMiddleware(config);

    const res$ = ajax.patch<T>(requestConfig.url, requestConfig.body, requestConfig.headers as Record<string, string>);
    return mapResultWithResponseMiddleware<T>(requestConfig, start)(res$);
  },
  post<T>(config: HttpConfig, scheduler?: SchedulerLike): Observable<HttpCall<T>> {
    const start = performance.now();
    const requestConfig = applyRequestMiddleware(config);

    const res$ = ajax.post<T>(requestConfig.url, requestConfig.body, requestConfig.headers as Record<string, string>);
    return mapResultWithResponseMiddleware<T>(requestConfig, start, scheduler)(res$);
  },
  postRAW(config: HttpConfig, scheduler?: SchedulerLike): Observable<HttpCall<string>> {
    const start = performance.now();
    const httpConfig = { ...config, method: 'POST', responseType: 'text' };
    const requestConfig = applyRequestMiddleware(httpConfig);

    const res$ = ajax<string>(requestConfig);
    return mapResultWithResponseMiddleware<string>(requestConfig, start, scheduler)(res$);
  },
  put<T>(config: HttpConfig): Observable<HttpCall<T>> {
    const start = performance.now();
    const requestConfig = applyRequestMiddleware(config);

    const res$ = ajax.put<T>(requestConfig.url, requestConfig.body, requestConfig.headers as Record<string, string>);
    return mapResultWithResponseMiddleware<T>(requestConfig, start)(res$);
  },
};
