import { SkeletonTheme } from 'react-loading-skeleton';
import { getCurrentTheme } from '@/store/sessions/sessions.selectors';
import { useSelector } from 'react-redux';
import React from 'react';

export interface AppThemeProviderProps {
  children: React.ReactNode;
}

export const AppThemeProvider: React.FC<AppThemeProviderProps> = ({ children }) => {
  const theme = useSelector(getCurrentTheme);
  return (
    <SkeletonTheme
      baseColor={theme === 'DARK' ? '#3b3e3f' : '#ebebeb'}
      highlightColor={theme === 'DARK' ? '#666666' : '#f5f5f5'}
    >
      {children}
    </SkeletonTheme>
  );
};
