import { type FC, type PropsWithChildren } from 'react';
import { useModalContext } from '@/components/shared/Modal/ModalContext';
import classNames from 'classnames';
import type { ModalProps } from '@/components/shared/Modal/Modal';

export const ModalHeader: FC<PropsWithChildren<ModalProps>> = ({ title, color, label }) => {
  const { close } = useModalContext();

  return (
    <div className="modal-header">
      <h3 className={classNames('modal-title', `text-${color}`)} id={label}>
        {title}
      </h3>
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={close}></button>
    </div>
  );
};
