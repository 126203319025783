import { type FC, type PropsWithChildren } from 'react';
import { Icon } from '@sgme/ui';
import { NavButton } from '@/components/AppNav/NavButton';
import type { NavButtonProps } from '@/components/AppNav/NavButton';

export type NavButtonWithIconProps = NavButtonProps & {
  icon: string;
};

export const NavButtonWithIcon: FC<PropsWithChildren<NavButtonWithIconProps>> = ({ children, icon, ...props }) => {
  return (
    <NavButton {...props} iconPosition="start">
      <Icon icon={icon} />
      {children}
    </NavButton>
  );
};
