import { useLocation, useMatch } from 'react-router-dom';
export function usePageTitle() {
  const location = useLocation();
  const match = useMatch(location.pathname);
  switch (true) {
    case match?.pathname === '/auctions/create': {
      document.title = 'New auction - Bidding';
      break;
    }
    case match?.pathname.endsWith('/edit'): {
      document.title = 'Edit auction - Bidding';
      break;
    }
    default: {
      document.title = 'Bidding';
    }
  }
}
