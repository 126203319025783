import { TextInput } from '@/components/shared/TextInput';
import { ToggleButtons } from '@/components/shared/ToggleButtons';
import { getAuctionsStatus } from '@/store/auctions';
import { mapWithTranslation } from '@/utils/mapWithTranslation';
import { useFieldContext } from './FieldContext';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export const ToggleButtonsField = () => {
  const intl = useIntl();
  const { fieldDefinition, values, onPatch } = useFieldContext();
  const { translationKey, availableItems, id } = fieldDefinition;

  const { isLoading: isEnrichLoading } = useSelector(getAuctionsStatus('enrich'));

  return (availableItems ?? []).length > 1 ? (
    <ToggleButtons<unknown>
      fieldLabel={`fieldId.${translationKey}`}
      items={availableItems?.map(mapWithTranslation)}
      selectedItem={values?.[id]}
      onChange={v => id && onPatch({ [id]: v ?? null })}
      isDisabled={isEnrichLoading}
    />
  ) : (
    <TextInput
      label={`fieldId.${translationKey}`}
      value={intl.formatMessage({ id: `availableItem.${availableItems?.[0]?.translationKey}` })}
      readOnly
    />
  );
};
