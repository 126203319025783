import Skeleton from 'react-loading-skeleton';
import type { FC } from 'react';

export interface FormFieldsLoadingSkeletonProps {
  columnSpan?: number;
}

export const FieldLoadingSkeleton: FC<FormFieldsLoadingSkeletonProps> = ({ columnSpan = 1 }) => {
  return (
    <div data-e2e="loading-skeleton" className={`col-${columnSpan} flex-fill mb-4`}>
      <Skeleton width="88px" height="18px" />
      <Skeleton height="30px" />
    </div>
  );
};
