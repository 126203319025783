import { LOCALE_CHANGED, SG_CONNECT_TOKEN_EXPIRED, THEME_CHANGED, THEME_INITIALIZED } from './sessions.actions';
import { initialSessionsState } from './sessions.model';
import { patchObject } from '@/utils/object/objects';
import type { Actions } from '@/store/app.actions';
import type { SessionsState } from './sessions.model';

export const sessionsReducer = (state: SessionsState = initialSessionsState, action: Actions): SessionsState => {
  switch (action.type) {
    case SG_CONNECT_TOKEN_EXPIRED:
      return patchObject(state, 'isExpired', true);
    case LOCALE_CHANGED:
      return patchObject(state, 'locale', action.locale);
    case THEME_INITIALIZED:
    case THEME_CHANGED:
      return patchObject(state, 'theme', action.theme);
    default:
      return state;
  }
};
