import { FormattedText } from '@/components/shared/FormattedText';
import classnames from 'classnames';
import type { FC } from 'react';
import type { IntlKey } from '@/locales';

export interface FieldWrapperProps {
  label: IntlKey;
  className?: string;
}

export const FieldWrapper: FC<React.PropsWithChildren<FieldWrapperProps>> = ({ label, children, className }) => {
  return (
    <div className={classnames(className)}>
      <label className="form-label d-block">
        <FormattedText as="div" id={label} className="mb-1" />
        {children}
      </label>
    </div>
  );
};
