import { SinglePicker } from '@/components/shared/SinglePicker';
import { useEffect, useState } from 'react';
import { useUnderlying } from '@/hooks/useUnderlying';
import type { FC } from 'react';
import type { IntlKey } from '@/locales';
import type { OptionItem } from '@/models/picker';
import type { StrategyTypeEnum } from '@/models';
import type { Underlying } from '@/api';

export interface UnderlyingInputProps {
  className?: string;
  strategyType: StrategyTypeEnum;
  label: IntlKey;
  underlying?: Underlying;
  disabled?: boolean;
  onSelect?: (u: Underlying | undefined) => void;
}

export const UnderlyingInput: FC<UnderlyingInputProps> = ({
  className,
  underlying,
  strategyType,
  label,
  disabled,
  onSelect,
}) => {
  const { search, response } = useUnderlying({ strategyType });
  const { underlyings } = response;

  const [optionItems, setOptionItems] = useState(underlying ? [toOptionItem(underlying)] : []);

  useEffect(() => {
    if (underlyings) {
      setOptionItems(underlyings.map(toOptionItem));
    }
  }, [underlyings]);

  return (
    <SinglePicker
      className={className}
      label={label}
      placeholder="Search and select"
      icon="search"
      hideClearButton
      onSearch={s => s && search(s)}
      items={optionItems}
      value={underlying}
      onSelect={underlying => underlying && onSelect && onSelect(underlying)}
      disabled={disabled}
    />
  );
};

function toOptionItem(underlying: Underlying): OptionItem {
  const { id, bloombergCode } = underlying;
  return {
    label: bloombergCode ?? id,
    value: underlying,
  };
}
