import { map } from 'rxjs';
import type { HttpClient } from '@/utils/http/httpClient.models';
import type { Observable } from 'rxjs';
import type { User } from '@/models/User';

export interface UsersApi {
  getCurrentUserInfo(): Observable<User>;
}

export const usersApiConnector = (httpClient: HttpClient): UsersApi => ({
  getCurrentUserInfo(): Observable<User> {
    return httpClient.getJSON<User>({ url: '/api/v1/users/me' }).pipe(map(({ payload }) => payload));
  },
});
