import { en } from '@/locales/en';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import type { IntlKey } from '@/locales';
import type { IntlShape } from 'react-intl';
import type { ValidationError } from '@/models';

export function toStrings(validationErrors: ValidationError[] = []): string[] {
  const result: string[] = [];

  validationErrors.forEach(error => {
    if (!error.fields) {
      return;
    }

    error.fields.forEach(field => {
      const translationKey = error.translationKey
        ? en[`validationError.${error.translationKey}` as IntlKey] ?? error.translationKey
        : '';
      if (error.values && Object.keys(error.values).length > 0) {
        Object.keys(error.values).forEach(key => {
          const value = error.values ? error.values[key] : '';
          result.push(`${en[`fieldId.${field}` as IntlKey] ?? field} ${key} ${value} ${translationKey}`);
        });
      } else {
        result.push(`${en[`fieldId.${field}` as IntlKey] ?? field} ${translationKey}`);
      }
    });
  });

  return result;
}

export function useGenerateValidationErrorMessages(errors?: ValidationError[]) {
  const intl = useIntl();
  return useMemo(() => errors?.map(error => generateFieldSpecificErrorMessages(intl, error)).flat(), [errors, intl]);
}

function generateFieldSpecificErrorMessages(intl: IntlShape, error: ValidationError) {
  const errorMessage = intl.formatMessage({
    id: `validationError.${error.translationKey}`,
    defaultMessage: error.translationKey,
  });

  if (error.fields.length === 0) {
    return [errorMessage];
  }

  return error.fields.map(
    field => `${intl.formatMessage({ id: `fieldId.${field}`, defaultMessage: field })} ${errorMessage}`,
  );
}
