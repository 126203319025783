import { ErrorState } from '@/components/shared/ErrorState';

export const UnauthorizedPage = () => {
  return (
    <main className="app-main container d-flex">
      <ErrorState
        icon="public_off"
        messages={[
          'This service is only available from our internal corporate network. Please connect through our corporate Wi-Fi network or VPN.',
          'Please contact us if you require any further assistance.',
        ]}
        showContactUsButton
        title="Access denied"
      />
    </main>
  );
};
