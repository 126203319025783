import { FieldWrapper } from '@/components/shared/FieldWrapper';
import { FormattedText } from '@/components/shared/FormattedText';
import { useCallback } from 'react';
import type { IntlKey } from '@/locales';

export interface ToggleButtonItem<T> {
  label?: string;
  value: T;
  translationKey?: IntlKey;
}

export interface ToggleButtonsProps<T> {
  fieldLabel: IntlKey;
  items?: ToggleButtonItem<T>[];
  className?: string;
  onChange?: (value: T) => void;
  selectedItem?: T;
  isDisabled?: boolean;
}

function isItemSelected<T>(selectedItem?: T, value?: T): boolean {
  return selectedItem === value;
}

export function ToggleButtons<T>({
  items,
  fieldLabel,
  className,
  onChange,
  selectedItem,
  isDisabled,
}: ToggleButtonsProps<T>) {
  const availableItems = items ?? [
    { label: 'Yes', value: true } as ToggleButtonItem<T>,
    { label: 'No', value: false } as ToggleButtonItem<T>,
  ];
  return (
    <FieldWrapper label={fieldLabel} className={className}>
      <div className="btn-group btn-group-toggle d-flex">
        {availableItems.map(item => (
          <Option
            key={`${item.label}-${item.value}`}
            item={item}
            onChange={onChange}
            isActive={isItemSelected(selectedItem, item.value)}
            isDisabled={isDisabled}
          />
        ))}
      </div>
    </FieldWrapper>
  );
}

export interface OptionProps<T> {
  item: ToggleButtonItem<T>;
  isActive?: boolean;
  isDisabled?: boolean;
  onChange?: (value: T) => void;
}
export function Option<T>({ item, isActive, isDisabled, onChange }: OptionProps<T>) {
  const onClick = useCallback(() => {
    if (item.value === undefined || onChange === undefined) {
      return;
    }

    onChange(item.value);
  }, [onChange, item]);
  return (
    <>
      <input
        className="btn-check"
        type="radio"
        onChange={onClick}
        checked={isActive}
        id={`${item.label}-${item.value}`}
        disabled={isDisabled}
      />
      <label className="btn btn-toggle-primary" htmlFor={`${item.label}-${item.value}`}>
        <FormattedText id={item.translationKey} defaultMessage={item.label} />
      </label>
    </>
  );
}
