import { NumberInput } from '@/components/shared/NumberInput';
import { getAuctionsStatus } from '@/store/auctions';
import { useFieldContext } from './FieldContext';
import { useSelector } from 'react-redux';

export const NumberField = () => {
  const { fieldDefinition, values, onPatch } = useFieldContext();
  const { translationKey, id, isReadOnly, unit, nbDecimals, maxLength } = fieldDefinition;

  const { isLoading: isEnrichLoading } = useSelector(getAuctionsStatus('enrich'));

  return (
    <NumberInput
      readOnly={isReadOnly || isEnrichLoading}
      label={`fieldId.${translationKey}`}
      value={values?.[id] as number}
      unit={unit}
      disableDecimals={fieldDefinition.disableDecimals}
      nbDecimals={nbDecimals}
      maxLength={maxLength}
      onBlur={v => id && !isReadOnly && onPatch({ [id]: v ?? null })}
    />
  );
};
