import { ContactUsButton } from '@/components/shared/ContactUsButton/ContactUsButton';
import { FormattedText } from '@/components/shared/FormattedText';
import { GoCurrentPageButton } from '@/components/shared/GoBackButton';
import { Icon } from '@sgme/ui';
import { getCurrentUserInfo } from '@/store/users';
import { useSelector } from 'react-redux';
import type { Color } from '@/models';
import type { FC, PropsWithChildren } from 'react';
import type { IntlKey } from '@/locales';

export interface ErrorStateProps {
  color?: Color;
  icon?: string;
  id?: string;
  messages?: IntlKey[];
  originMessage?: string;
  showContactUsButton?: boolean;
  showGoBackButton?: boolean;
  title?: IntlKey;
  my?: 4 | 8 | 12 | 16 | 24 | 32 | 48 | 64 | 96 | 128;
}

export const ErrorState: FC<PropsWithChildren<ErrorStateProps>> = ({
  children,
  color = 'danger',
  icon = 'error_outline',
  id,
  messages = ['An unexpected error occurred,', 'please try again or contact us if the problem persists.'],
  my = 128,
  originMessage,
  showContactUsButton = false,
  showGoBackButton = false,
  title = 'Error(s) occurred',
}) => {
  const user = useSelector(getCurrentUserInfo);
  return (
    <div id={id} className={`flex-fill text-${color} text-center my-${my}px`}>
      <Icon icon={icon} size="xl" />
      <FormattedText as="h4" id={title} />
      {messages.map(msg => (
        <FormattedText as="p" id={msg} key={msg} />
      ))}
      {originMessage && user?.isSgGroupUser && <pre className="text-center text-wrap mt-3 mb-0">{originMessage}</pre>}
      {(children || showContactUsButton || showGoBackButton) && (
        <div className="mt-4">
          {showContactUsButton && <ContactUsButton className="mx-1" />}
          {showGoBackButton && <GoCurrentPageButton className="mx-1" />}
          {children}
        </div>
      )}
    </div>
  );
};
