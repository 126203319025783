import { type ComponentPropsWithoutRef, type ElementType, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';
import type { IntlKey } from '@/locales';

const NonExistId = v4();

type FormattedTextProps<T extends ElementType> = {
  as?: T;
  id?: IntlKey;
  text?: string;
  defaultMessage?: string;
} & ComponentPropsWithoutRef<T>;

export function FormattedText<T extends ElementType>({
  as,
  id,
  text,
  defaultMessage,
  ...props
}: FormattedTextProps<T>) {
  const Component = as ?? Fragment;

  return (
    <Component {...props}>
      <FormattedMessage id={(id ?? NonExistId) as IntlKey} defaultMessage={defaultMessage ?? text ?? '-'} />
    </Component>
  );
}
