import { type FC, useEffect } from 'react';

export interface RedirectProps {
  to: string;
}

export const Redirect: FC<RedirectProps> = ({ to }) => {
  useEffect(() => {
    location.replace(to);
  }, [to]);

  return null;
};
