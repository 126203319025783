import { createContext, useContext } from 'react';
import type { FieldDefinition, VariationParameters } from '@/models';

interface IFieldContext {
  fieldDefinition: FieldDefinition;
  values: VariationParameters;
  onPatch: (vp: VariationParameters) => void;
}

export const FieldContext = createContext<IFieldContext | null>(null);

export const useFieldContext = () => {
  const context = useContext(FieldContext);

  if (!context) {
    throw new Error('useFieldContext must be used within a FieldContextProvider');
  }

  return context;
};
