import { FormBuilder } from '@/components/FormBuilder';
import { FormLoadingSkeleton } from '@/components/shared/FormLoadingSkeleton';
import { enrichStrategyFormThunk, getFieldsMetadata, getPendingEnrichmentValue } from '@/store/auctions';
import { useDispatch, useSelector } from 'react-redux';
import type { BlockId } from '@/models';
import type { FC } from 'react';
import type { ThunkDispatch } from '@/store';

export interface BlockBuilderProps {
  blockId: BlockId;
  isResponsive?: boolean;
}

export const BlockBuilder: FC<BlockBuilderProps> = ({ blockId, isResponsive }) => {
  const dispatch = useDispatch<ThunkDispatch>();
  const fieldsMetadata = useSelector(getFieldsMetadata(blockId));
  const values = useSelector(getPendingEnrichmentValue);

  if (fieldsMetadata === undefined) {
    return <FormLoadingSkeleton />;
  }

  return (
    <FormBuilder
      fieldsMetadata={fieldsMetadata}
      values={values}
      isResponsive={isResponsive}
      onPatch={vp => dispatch(enrichStrategyFormThunk(vp))}
    />
  );
};
