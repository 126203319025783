import { Fragment } from 'react';
import { format as fnsFormat } from 'date-fns';
import { getTimeZoneAbbreviation } from '@/utils/date';
import type { ComponentPropsWithoutRef, ElementType } from 'react';

type FormattedDateProps<T extends ElementType> = {
  as?: T;
  format?: 'short' | 'long';
  value?: string;
  timezoneId?: string;
} & ComponentPropsWithoutRef<T>;

export function FormattedDate<T extends ElementType>({
  as,
  format = 'short',
  value,
  timezoneId,
  ...props
}: FormattedDateProps<T>) {
  const Component = as ?? Fragment;
  return (
    <Component {...props}>
      {`${value ? fnsFormat(value, format === 'short' ? 'dd MMM yyyy' : 'dd MMM yyyy, HH:mm') : '-'}${timezoneId !== undefined ? ` ${getTimeZoneAbbreviation(new Date(), timezoneId)}` : ''}`}
    </Component>
  );
}
