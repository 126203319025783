import type { FeatureName, Features, Permission } from '@/models';
import type { RootState } from '@/store/app.state';
import type { User } from '@/models/User';
import type { UsersState } from '@/store/users/users.model';

const selectUserState = (state: RootState): UsersState => state.users;

export function getCurrentUserInfo(state: RootState): User | undefined {
  const userState = selectUserState(state);
  return userState.user ?? undefined;
}

export const isCurrentUserAuthenticated = (state: RootState): boolean => getCurrentUserInfo(state) !== undefined;

export function getUserFeatures(state: RootState): Features | undefined {
  const userState = selectUserState(state);
  return userState.user?.features ?? undefined;
}

export function isFeatureEnabled(feature: FeatureName) {
  return (state: RootState) => {
    const userState = selectUserState(state);
    return userState.user?.features[feature] ?? false;
  };
}

export function getUserPermissions(state: RootState) {
  const userState = selectUserState(state);
  return userState.user?.permissions ?? [];
}

export function hasPermission(permission: Permission) {
  return (state: RootState) => {
    return getUserPermissions(state).includes(permission);
  };
}

export function isUnauthorizedUser(state: RootState) {
  const userState = selectUserState(state);
  return userState.user === undefined && userState.error?.status !== 403;
}

export function isLoadingUserInfo(state: RootState) {
  const userState = selectUserState(state);
  return userState.isLoading;
}
