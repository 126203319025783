import { type FC, type PropsWithChildren, useState } from 'react';
import { FoldableCardContext } from '@/components/shared/FoldableCard/FoldableCardContext';
import classNames from 'classnames';

export interface FoldableCardProps {
  className?: string;
  defaultOpen?: boolean;
}
export const FoldableCard: FC<PropsWithChildren<FoldableCardProps>> = ({
  className,
  defaultOpen = false,
  children,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const toggle = () => setOpen(!open);

  return (
    <FoldableCardContext.Provider value={{ open, toggle }}>
      <div className={classNames('card card-bordered', className)}>{children}</div>
    </FoldableCardContext.Provider>
  );
};
