import classNames from 'classnames';
import type { FC } from 'react';

export const Th: FC<React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>> = ({
  children,
  className,
  ...others
}) => {
  return (
    <th {...others} className={classNames('fw-semibold', className)}>
      {children}
    </th>
  );
};
