import { auctionsReducer } from '@/store/auctions';
import { combineReducers } from 'redux';
import { sessionsReducer } from '@/store/sessions/sessions.reducer';
import { uiReducer } from '@/store/ui';
import { usersReducer } from '@/store/users/users.reducer';
import type { Actions } from '@/store/app.actions';
import type { CombinedState, Reducer } from 'redux';
import type { RootState } from '@/store/app.state';

export const createRootReducer = (): Reducer<CombinedState<RootState>, Actions> =>
  combineReducers<RootState>({
    users: usersReducer,
    auctions: auctionsReducer,
    ui: uiReducer,
    sessions: sessionsReducer,
  });
