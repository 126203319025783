import type { IntlKey } from '@/locales';
import type { ListItem } from '@/models';
import type { OptionItem, OptionValue } from '@/models/picker';

export function mapWithTranslation<T extends OptionValue>(item: ListItem): OptionItem {
  const { value, translationKey } = item;
  return {
    ...item,
    value: value as T,
    translationKey: translationKey ? (`${translationKey}.${value}` as IntlKey) : undefined,
  };
}
