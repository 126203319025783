import { type FC } from 'react';
import { FieldContext } from '@/components/FormBuilder/FieldContext';
import { getColumnSpanClass, getField } from './FormBuilder.helper';
import classNames from 'classnames';
import type { FieldDefinition, VariationParameters } from '@/models';

interface FormBuilderProps {
  fieldsMetadata: Record<string, FieldDefinition> | undefined;
  values?: VariationParameters;
  isResponsive?: boolean;
  onPatch: (vp: VariationParameters) => void;
}

export const FormBuilder: FC<FormBuilderProps> = ({ fieldsMetadata, values, onPatch }) => {
  if (!fieldsMetadata || !values) {
    return null;
  }

  return (
    <div className="row row-cols-12">
      {Object.values(fieldsMetadata).map(fieldDefinition => {
        const { id, type, isHidden, columnSpan } = fieldDefinition;
        return (
          <div key={id} className={classNames(`mb-4`, getColumnSpanClass(columnSpan), isHidden ? 'd-none' : 'd-block')}>
            <FieldContext.Provider value={{ fieldDefinition, values, onPatch }}>{getField(type)}</FieldContext.Provider>
          </div>
        );
      })}
    </div>
  );
};
