import { FieldLoadingSkeleton } from '@/components/shared/FieldLoadingSkeleton';
import type { FC } from 'react';

export interface FormLoadingSkeletonProps {
  numOfRows?: number;
}

export const FormLoadingSkeleton: FC<FormLoadingSkeletonProps> = ({ numOfRows = 2 }) => {
  return (
    <>
      {[...Array(numOfRows)].map((_, j) => (
        <div className="row row-cols-12" key={j}>
          <FieldLoadingSkeleton key={j + '-a'} columnSpan={3} />
          <FieldLoadingSkeleton key={j + '-b'} columnSpan={3} />
          <FieldLoadingSkeleton key={j + '-c'} columnSpan={3} />
          <FieldLoadingSkeleton key={j + '-d'} columnSpan={3} />
        </div>
      ))}
    </>
  );
};
