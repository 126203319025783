import type { ErrorResponse } from '@/models/Responses/ErrorResponse';
import type { User } from '@/models/User';

export type UsersActions = CurrentUserInfoRequested | CurrentUserInfoFetched | CurrentUserInfoFetchFailed;

export const CURRENT_USER_INFO_REQUESTED = '[Users API] Current user info requested';
export const currentUserInfoRequested = () =>
  ({
    type: CURRENT_USER_INFO_REQUESTED,
  }) as const;
export type CurrentUserInfoRequested = ReturnType<typeof currentUserInfoRequested>;

export const CURRENT_USER_INFO_FETCHED = '[Users API] Current user info fetched';
export const currentUserInfoFetched = (user: User) =>
  ({
    type: CURRENT_USER_INFO_FETCHED,
    user,
  }) as const;
export type CurrentUserInfoFetched = ReturnType<typeof currentUserInfoFetched>;

export const CURRENT_USER_INFO_FETCH_FAILED = '[Users API] Current user info fetch failed';
export const currentUserInfoFetchFailed = (error: ErrorResponse) =>
  ({
    type: CURRENT_USER_INFO_FETCH_FAILED,
    error,
  }) as const;
export type CurrentUserInfoFetchFailed = ReturnType<typeof currentUserInfoFetchFailed>;
