import { localStorageApi } from '@/api/localStorage';

const sgbsVersion = import.meta.env.VITE_SGBS_VERSION;
const cdnUrl = `https://sgwt-cdn-sgbs.sgmarkets.com/sg-bootstrap/${sgbsVersion}`;

export type SgBootstrapTheme = 'DARK' | 'STANDARD';
export const applySgBootstrapTheme = (theme: SgBootstrapTheme | undefined): void => {
  updateThemeUrls(theme ?? 'STANDARD');
};

function variableSgbsUrls(theme: string | null) {
  const variation = theme === 'DARK' ? 'dark' : 'standard';
  return {
    sgbs: `${cdnUrl}/core/css/sg-bootstrap-${variation}.min.css`,
    icons: `${cdnUrl}/icons/index.min.css`,
  };
}

function appendUniqueLinkToDocument(id: string, url: string) {
  const linkElement = document.querySelector<HTMLLinkElement>(`link[id="${id}"]`);
  const newLinkElement = document.createElement('link');
  newLinkElement.rel = 'stylesheet';
  newLinkElement.id = id;
  newLinkElement.href = url;
  if (linkElement !== null) {
    newLinkElement.onload = () => {
      document.head.removeChild(linkElement);
    };
  }
  document.head.appendChild(newLinkElement);
}

function updateThemeUrls(theme: SgBootstrapTheme) {
  Object.entries(variableSgbsUrls(theme)).forEach(([id, url]) => {
    appendUniqueLinkToDocument(id, url);
  });
}

export const initializeTheme = (): SgBootstrapTheme | undefined => {
  const preferredTheme = localStorageApi.get<SgBootstrapTheme>('APP_THEME');
  updateThemeUrls(preferredTheme ?? 'STANDARD');
  appendUniqueLinkToDocument('icons', `${cdnUrl}/icons/index.min.css`);
  return preferredTheme;
};
