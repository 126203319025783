import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';

export interface CardBodyProps {
  className?: string;
}

export const CardBody: FC<PropsWithChildren<CardBodyProps>> = ({ children, className }) => {
  return <div className={classNames('card-body p-0', className)}>{children}</div>;
};
