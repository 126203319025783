import { httpClient } from '@/utils/http';
import { map } from 'rxjs';
import queryString from 'query-string';
import type { Schemas, paths } from '@/models';

export type ReferentialApi = ReturnType<typeof referentialApiConnector>;
export type Underlying = Schemas['OUnderlying'];

export const referentialApiConnector = () => ({
  getUnderlyings(params: paths['/api/v1/referential/underlyings']['get']['parameters']) {
    const { query } = params;
    const queryParams = query ? `${queryString.stringify(query)}` : '';

    return httpClient
      .getJSON<{ entries: Underlying[] }>({ url: `/api/v1/referential/underlyings?${queryParams}` })
      .pipe(map(({ payload }) => payload));
  },
});
