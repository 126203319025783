import { CreatePage } from './Create';
import { CurrentPage } from './Current';
import { EditPage } from './Edit';
import { ForbiddenPage } from './Forbidden';
import { HistoryPage } from './History';
import { MainOutlet } from '@/routes/MainOutlet';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { NotFoundPage } from './NotFound';
import { Redirect } from '@/routes/Redirect';
import { RootOutlet } from './RootOutlet';
import { UnauthorizedPage } from './Unauthorized';
import { getUnauthorizedRedirectUrl } from '@/routes/helpers';
import { getUserPermissions, isUnauthorizedUser } from '@/store/users';
import { useSelector } from 'react-redux';
import React, { type FC, useCallback, useMemo } from 'react';
import type { Permission } from '@/models';
import type { RoutePath } from '@/store/router';

export const AppRouter: FC = () => {
  const isUnauthorized = useSelector(isUnauthorizedUser);
  const permissions = useSelector(getUserPermissions);

  const withPermission = useCallback(
    (checkPermission: Permission, element: React.ReactNode, otherwiseTo: RoutePath) => {
      return permissions.includes(checkPermission) ? element : <Navigate to={otherwiseTo} />;
    },
    [permissions],
  );

  const authorizedRoutes = useMemo(
    () =>
      createBrowserRouter([
        {
          path: '/',
          element: permissions.includes('ACCESS_TO_SGM_BIDDING_PLATFORM') ? (
            <RootOutlet />
          ) : (
            <Redirect to={getUnauthorizedRedirectUrl()} />
          ),
          children: [
            {
              index: true,
              element: <Navigate to="/auctions/current" />,
            },
            {
              path: 'auctions',
              element: <MainOutlet />,
              children: [
                {
                  path: 'current',
                  element: withPermission('READ_CURRENT_AUCTIONS', <CurrentPage />, '/auctions/history'),
                },
                {
                  path: 'history',
                  element: withPermission('READ_AUCTION_HISTORY', <HistoryPage />, '/forbidden'),
                },
                {
                  path: 'create',
                  element: withPermission('READ_AUCTION_DETAILS', <CreatePage />, '/forbidden'),
                },
                {
                  path: ':auctionUuid/edit',
                  element: withPermission('READ_AUCTION_DETAILS', <EditPage />, '/forbidden'),
                },
              ],
            },
            { path: 'forbidden', element: <ForbiddenPage /> },
            { path: '*', element: <NotFoundPage /> },
          ],
        },
      ]),
    [permissions, withPermission],
  );

  const unAuthorizedRoutes = useMemo(
    () =>
      createBrowserRouter([
        {
          path: '*',
          element: <UnauthorizedPage />,
        },
      ]),
    [],
  );

  return <RouterProvider router={isUnauthorized ? unAuthorizedRoutes : authorizedRoutes} />;
};
