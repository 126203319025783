import { auctionsEpics } from '@/store/auctions';
import { combineEpics } from 'redux-observable';
import { sessionsEpics } from '@/store/sessions';
import { usersEpics } from '@/store/users';
import type { Actions } from '@/store/app.actions';
import type { EmptyObject } from 'redux';
import type { Epic } from 'redux-observable';
import type { RootState } from '@/store/app.state';
export type BpEpicDependencies = EmptyObject;

export type BpEpic = Epic<Actions, Actions, RootState, BpEpicDependencies>;
export const rootEpic = (): BpEpic => combineEpics(usersEpics(), auctionsEpics(), sessionsEpics());
