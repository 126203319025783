import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import type { FC, PropsWithChildren } from 'react';
import type { RoutePath } from '@/store/router';

export interface NavTabProps {
  to: RoutePath;
}

export const NavTab: FC<PropsWithChildren<NavTabProps>> = ({ children, to }) => {
  return (
    <li className="nav-item">
      <NavLink className={({ isActive }) => classnames('nav-link', { active: isActive })} to={to}>
        {children}
      </NavLink>
    </li>
  );
};
