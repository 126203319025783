import { SgwtWidgetsContext } from '@/contexts/SgwtWidgets/SgwtWidgetsContext';
import { useContext, useEffect, useRef } from 'react';
import type { FC } from 'react';
import type { SgwtMiniFooterHTMLElement } from '@/types/sgwt-widgets';

export const MiniFooter: FC = () => {
  const sgwtWidgets = useContext(SgwtWidgetsContext);
  const ref = useRef<SgwtMiniFooterHTMLElement>();
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    sgwtWidgets.setMiniFooter(ref.current);
  }, [sgwtWidgets]);

  return (
    <sgwt-mini-footer
      contact-us-by-help-center="sgwt-help-center"
      design="light"
      mode="sg-markets"
      // @ts-expect-error
      ref={ref}
      type="compact"
    />
  );
};
