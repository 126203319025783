import type { Locale } from '@/locales';
import type { SgBootstrapTheme } from '@/theme';
export interface SessionsState {
  isExpired: boolean;
  locale: Locale;
  theme: SgBootstrapTheme | undefined;
}

export const initialSessionsState: SessionsState = {
  isExpired: false,
  locale: 'en',
  theme: undefined,
};
