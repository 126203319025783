import { BlockBuilder } from '@/components/BlockBuilder';
import { Card, CardBody, CardHeader, CardTitle } from '@/components/shared/Card';
import { FieldLoadingSkeleton } from '@/components/shared/FieldLoadingSkeleton';
import { FormattedMessage } from 'react-intl';
import { SinglePicker } from '@/components/shared/SinglePicker';
import { getAuctionsStatus, initStrategyFormRequested } from '@/store/auctions';
import { getStrategyType } from '@/store/ui';
import { orderBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggleFeature } from '@/hooks/useToggleFeature/useToggleFeature';
import classNames from 'classnames';
import type { FC } from 'react';
import type { OptionItem, StrategyTypeEnum } from '@/models';

interface InstrumentBlockProps {
  page: 'create' | 'edit';
  className?: string;
}

export const InstrumentBlock: FC<InstrumentBlockProps> = ({ className, page }) => {
  const dispatch = useDispatch();
  const isTotalReturnFutureOn = useToggleFeature('TotalReturnFuture');
  const strategyType = useSelector(getStrategyType(page));

  const onStrategyTypeChanged = useCallback(
    (strategyType: StrategyTypeEnum | undefined) => {
      if (strategyType === undefined) {
        return;
      }
      dispatch(initStrategyFormRequested(page, strategyType));
    },
    [dispatch, page],
  );

  const filteredSupportedType = useMemo(() => {
    if (isTotalReturnFutureOn) {
      return orderBy(SupportedType, ['translationKey'], ['asc']);
    }
    return orderBy(
      SupportedType.filter(type => type.value !== 'TOTAL_RETURN_FUTURE'),
      ['translationKey'],
      ['asc'],
    );
  }, [isTotalReturnFutureOn]);

  const { isLoading: isEnrichLoading } = useSelector(getAuctionsStatus('enrich'));

  return (
    <Card className={classNames('card-bordered pb-0', className)}>
      <CardHeader>
        <CardTitle>
          <FormattedMessage id="Instrument" />
        </CardTitle>
      </CardHeader>
      <CardBody className="container-fluid">
        {strategyType ? (
          <div className="row row-cols-12">
            <SinglePicker
              className="col-xs-12 col-md-6 col-xl-4 col-xxl-3 mb-4"
              label="Instrument type"
              items={orderBy(filteredSupportedType, ['translationKey'], ['asc'])}
              value={strategyType}
              onSelect={onStrategyTypeChanged}
              hideClearButton={true}
              disabled={isEnrichLoading}
            />
          </div>
        ) : (
          <FieldLoadingSkeleton columnSpan={3} />
        )}
        <BlockBuilder blockId="Instrument" />
      </CardBody>
    </Card>
  );
};

const SupportedType: OptionItem[] = [
  { translationKey: 'instrumentType.CALL', value: 'CALL' },
  { translationKey: 'instrumentType.PUT', value: 'PUT' },
  { translationKey: 'instrumentType.CALL_SPREAD', value: 'CALL_SPREAD' },
  { translationKey: 'instrumentType.PUT_SPREAD', value: 'PUT_SPREAD' },
  { translationKey: 'instrumentType.TOTAL_RETURN_FUTURE', value: 'TOTAL_RETURN_FUTURE' },
];
