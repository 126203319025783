import { SinglePicker } from '@/components/shared/SinglePicker';
import { getAuctionsStatus } from '@/store/auctions';
import { mapSalesToOptionItems } from '@/components/DealersListBlock/DealersListBlock.helpers';
import { useCallback } from 'react';
import { useFetchSales } from '@/hooks/useFetchSales';
import { useFieldContext } from '@/components/FormBuilder/FieldContext';
import { useSelector } from 'react-redux';
import type { Sales } from '@/models';

export const SalesField = () => {
  const sales = useFetchSales();
  const { fieldDefinition, values, onPatch } = useFieldContext();
  const { translationKey, id: fieldId } = fieldDefinition;

  const { isLoading: isEnrichLoading } = useSelector(getAuctionsStatus('enrich'));

  const onSalesSelected = useCallback(
    (sales?: Sales) => {
      if (sales) {
        onPatch({ [fieldId]: sales });
      }
    },
    [fieldId, onPatch],
  );

  return (
    <SinglePicker
      label={`fieldId.${translationKey}`}
      icon="person"
      items={mapSalesToOptionItems(sales)}
      value={values?.[fieldId] as Sales}
      onSelect={onSalesSelected}
      hideClearButton={true}
      disabled={isEnrichLoading}
    />
  );
};
