import { httpClient } from '@/utils/http';
import { map } from 'rxjs';
import type { Dealers, Sales } from '@/models';

export type DealersApi = ReturnType<typeof dealersApiConnector>;
export const dealersApiConnector = () => ({
  getSales() {
    return httpClient
      .getJSON<{ entries: Sales[] }>({ url: `/api/v1/referential/sales` })
      .pipe(map(({ payload }) => payload));
  },
  getDealers(sales: string) {
    return httpClient
      .getJSON<{ entries: Dealers[] }>({
        url: `/api/v1/referential/counterparts?salesUserName=${sales}`,
      })
      .pipe(map(({ payload }) => payload));
  },
});
