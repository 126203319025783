import { DealerPriceCell } from '@/components/DealersListTable/DealerPriceCell';
import { FieldWrapper } from '@/components/shared/FieldWrapper';
import { Modal } from '@/components/shared/Modal';
import { NumberInput } from '@/components/shared/NumberInput';
import { SinglePicker } from '@/components/shared/SinglePicker';
import { TimePicker } from '@/components/shared/TimePicker';
import { applyTimeAndConvertToUTC, formatTimeWithoutTimeZoneAbbreviation, getTimeZoneAbbreviation } from '@/utils/date';
import {
  closeOverrideModal,
  getOverrideAuctionUuid,
  getOverrideInstrument,
  getOverrideModalConfirmationDisabled,
  overrideRequested,
} from '@/store/ui';
import { getBlotterAuctionByUuid, overrideQuoteRequested } from '@/store/auctions';
import { getColumnSpanClass } from '@/components/FormBuilder/FormBuilder.helper';
import { hasPermission } from '@/store/users';
import { sortBy } from 'lodash';
import { toZonedTime } from 'date-fns-tz';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useToggleFeature } from '@/hooks/useToggleFeature/useToggleFeature';
import type { ModalProps } from '@/components/shared/Modal';
import type { OptionItem, RfqDealers } from '@/models';

interface OverrideModalProps extends ModalProps {
  initialDisplayed?: boolean;
}

const OverrideModal: React.FC<OverrideModalProps> = ({ initialDisplayed }) => {
  const dispatch = useDispatch();

  const instrument = useSelector(getOverrideInstrument());
  const auctionUuid = useSelector(getOverrideAuctionUuid());
  const isConfirmationDisabled = useSelector(getOverrideModalConfirmationDisabled());
  const auction = useSelector(getBlotterAuctionByUuid(auctionUuid));

  const workflowStatus = auction?.auction?.workflowStatus;
  const isSelectAuctionWinnerEnabled = useToggleFeature('SelectAuctionWinner');
  const hasSelectAuctionWinnerPermission = useSelector(hasPermission('SELECT_AUCTION_WINNER'));
  const hasAuctionCutOffPassed = workflowStatus === 'AUCTION_CUTOFF_PASSED';

  const shouldSelectAuctionWinner = useMemo(
    () => isSelectAuctionWinnerEnabled && hasSelectAuctionWinnerPermission && hasAuctionCutOffPassed,
    [isSelectAuctionWinnerEnabled, hasSelectAuctionWinnerPermission, hasAuctionCutOffPassed],
  );
  const dealerList = sortBy(
    auction?.auction?.rfqDealers?.filter(
      dealer => dealer.dealerRfqId !== undefined && dealer.sendForBidding === true,
    ) ?? [],
    o => o.salesCounterparty?.name,
  );
  const auctionBaseRfq = auction?.auction?.auctionBaseRfq;

  const [selectedDealerRfqId, setSelectedDealerRfqId] = useState<string | undefined>(undefined);
  const selectedDealer = useMemo(
    () => dealerList?.find(d => d.dealerRfqId === selectedDealerRfqId),
    [selectedDealerRfqId, dealerList],
  );

  const timezoneId = auction?.auction.auctionBaseRfq?.salesDiffusion?.salesValo?.timeZoneId;
  const receiveTime = useMemo(() => {
    const receivedTime = selectedDealer?.receiveTime;
    if (timezoneId === undefined || receivedTime === undefined) {
      return undefined;
    }
    return formatTimeWithoutTimeZoneAbbreviation(receivedTime);
  }, [selectedDealer, timezoneId]);
  useEffect(() => {
    setPrice(selectedDealer?.price?.value?.toString() ?? undefined);
    setTime(receiveTime);
  }, [selectedDealer, receiveTime]);
  const [time, setTime] = useState<string | undefined>(undefined);
  const [price, setPrice] = useState<string | undefined>(undefined);

  const confirmEnabled = useMemo(
    () => !isConfirmationDisabled && price !== undefined && time !== undefined,
    [price, time, isConfirmationDisabled],
  );
  const dealerOptions: OptionItem[] =
    dealerList?.map(d => {
      return {
        value: d.dealerRfqId ?? '',
        label: d.salesCounterparty?.name ?? '',
      };
    }) ?? [];

  const timezone =
    auction?.auction.auctionBaseRfq?.salesDiffusion?.salesValo?.timeZoneId !== undefined
      ? getTimeZoneAbbreviation(new Date(), auction?.auction.auctionBaseRfq?.salesDiffusion?.salesValo?.timeZoneId)
      : undefined;

  function onConfirm() {
    const originalValue = toZonedTime(
      selectedDealer?.receiveTime ?? auction?.auction?.startTime ?? '',
      timezoneId ?? '',
    );
    const utcDateTime = applyTimeAndConvertToUTC(originalValue, time ?? receiveTime ?? '', timezoneId ?? '');
    dispatch(overrideRequested());
    dispatch(
      overrideQuoteRequested(
        auctionUuid ?? '',
        selectedDealerRfqId,
        Number(price),
        selectedDealer?.price?.unit ?? auctionBaseRfq?.priceFormat?.unit,
        selectedDealer?.price?.type ?? auctionBaseRfq?.priceFormat?.type,
        utcDateTime,
      ),
    );
  }
  const defaultColumnSpan = getColumnSpanClass({
    xs: 12,
    md: 6,
    xl: 4,
    xxl: 4,
  });
  return (
    <Modal
      initialDisplayed={initialDisplayed}
      id="override-modal"
      title={`Override - ${instrument}`}
      bordered
      minWidth={600}
      children={
        <>
          <SinglePicker
            label={`modal.override.fieldId.dealer`}
            items={dealerOptions}
            value={selectedDealerRfqId}
            onSelect={v => {
              setSelectedDealerRfqId(v as string | undefined);
            }}
            hideClearButton={false}
          />
          {selectedDealerRfqId !== undefined && (
            <>
              <div className={'w-100'}>
                <div className="m-0 mt-4 p-3 bg-lvl2 row row-cols-12 w-100">
                  <div className={defaultColumnSpan}>
                    <FieldWrapper label={'Price'}>
                      <DealerPriceCell
                        shouldSelectAuctionWinner={shouldSelectAuctionWinner}
                        dealer={selectedDealer as RfqDealers}
                        auctionBaseRfq={auctionBaseRfq}
                        showPriceAndUnitOnly={true}
                        displayInCell={false}
                      />
                    </FieldWrapper>
                  </div>
                  <div className={defaultColumnSpan}>
                    <FieldWrapper label={'Time'}>
                      <label>{receiveTime ?? '-'}</label>
                    </FieldWrapper>
                  </div>
                  <div className={defaultColumnSpan}>
                    <FieldWrapper label={'Timezone'}>
                      <label>{receiveTime ? timezone : '-'}</label>
                    </FieldWrapper>
                  </div>
                </div>
                <hr />
                <h6>New override details</h6>
                <div className="pt-3 row row-cols-12">
                  <div className={defaultColumnSpan}>
                    <NumberInput
                      label={'Price'}
                      unit={selectedDealer?.price?.unit ?? auctionBaseRfq?.priceFormat?.unit ?? ''}
                      disableDecimals={false}
                      nbDecimals={4}
                      onChange={v => setPrice(v.currentTarget.value)}
                      value={selectedDealer?.price?.value}
                    />
                  </div>
                  <div className={defaultColumnSpan}>
                    <TimePicker
                      label="Time"
                      className={`mb-4`}
                      value={time ?? receiveTime}
                      onChange={v => setTime(v)}
                    />
                  </div>
                  <div className={defaultColumnSpan}>
                    <FieldWrapper label={'Timezone'}>
                      <label className="pt-1">{timezone}</label>
                    </FieldWrapper>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      }
      onClose={() => dispatch(closeOverrideModal())}
      onCancel={() => dispatch(closeOverrideModal())}
      confirmActionDisabled={!confirmEnabled}
      onConfirm={() => onConfirm()}
      loading={isConfirmationDisabled}
    />
  );
};

export default OverrideModal;
