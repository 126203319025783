import { FormattedText } from '@/components/shared/FormattedText';
import { SinglePicker } from '@/components/shared/SinglePicker';
import { closeDerogation, getPdcDerogationParameters, getPdcModalIsDerogationOpened } from '@/store/ui';
import { derogateRequested } from '@/store/auctions';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { OptionItem } from '@/models';

interface PdcDerogationCardProps {
  rfqId: string | undefined;
  derogationReasonOptionItems?: OptionItem[];
}

const PdcDerogationCard: React.FC<PdcDerogationCardProps> = ({ rfqId, derogationReasonOptionItems }) => {
  const dispatch = useDispatch();
  const derogationParameters = useSelector(getPdcDerogationParameters());
  const isDerogationOpened = useSelector(getPdcModalIsDerogationOpened());
  const [selectedDerogationReason, setSelectedDerogationReason] = useState<string | undefined>(undefined);
  const [showTechnicalDetails, setShowTechnicalDetails] = useState<boolean>(false);

  useEffect(() => {
    setSelectedDerogationReason(undefined);
  }, [derogationReasonOptionItems]);

  const onConfirmDerogation = useCallback(
    (pdcUuid: string | undefined) => {
      if (pdcUuid !== undefined && selectedDerogationReason !== undefined) {
        dispatch(derogateRequested(selectedDerogationReason, pdcUuid, rfqId));
      }
    },
    [selectedDerogationReason, rfqId, dispatch],
  );

  const onCancelDerogation = useCallback(() => {
    setShowTechnicalDetails(false);
    dispatch(closeDerogation());
  }, [dispatch, setShowTechnicalDetails]);

  if (!isDerogationOpened) {
    return <></>;
  }

  return (
    <>
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <p>
            <span className="text-secondary">Leg {derogationParameters?.legIndex}</span> /{' '}
            <span className="text-primary fw-semibold">{derogationParameters?.typeLabel}</span>
          </p>
          {derogationParameters?.isEligible && <div className="badge rounded-pill badge-outline-primary">Eligible</div>}
        </div>
      </div>
      <div className="card-body">
        <p>
          {derogationParameters?.typeLabel} On Boarding: FOR ACTION: Our client has NO reporting solution and NOT
          on-boarded to Portfolio Reconciliation. Please liaise with our client to solve these issues asap.
        </p>
        <div
          className="w-100 d-flex justify-content-center align-items-center text-secondary pt-4 pb-1 fw-bold"
          onClick={() => setShowTechnicalDetails(!showTechnicalDetails)}
        >
          <div
            className="w-100 h-0"
            style={{
              color: 'lightGray',
              borderStyle: 'solid',
              borderWidth: '1px',
            }}
          />
          <button className="btn btn-flat text-secondary w-75">
            View technical details{' '}
            <i className="icon icon-sm text-secondary">
              {showTechnicalDetails ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </i>
          </button>
          <div
            className="w-100 h-0"
            style={{
              color: 'lightGray',
              borderStyle: 'solid',
              borderWidth: '1px',
            }}
          />
        </div>
        {showTechnicalDetails && <div className="pb-3">{derogationParameters?.message}</div>}

        <SinglePicker
          className="pt-3"
          label={`fieldId.DerogationReason`}
          items={derogationReasonOptionItems}
          value={derogationParameters?.derogationReason}
          onSelect={v => v && setSelectedDerogationReason(v as string)}
          hideClearButton={true}
          disabled={false}
          onBlurEnabled={false}
        />
      </div>
      <div className="card-footer d-flex justify-content-end">
        <button className="btn btn-secondary btn-lg" onClick={() => onCancelDerogation()}>
          Cancel
        </button>
        <button
          className="btn btn-primary btn-lg ms-2"
          onClick={() => onConfirmDerogation(derogationParameters?.pdcUuid)}
          disabled={selectedDerogationReason === undefined}
        >
          <FormattedText id="Confirm derogation" as="span" />
        </button>
      </div>
    </>
  );
};

export default PdcDerogationCard;
