import { IntlProvider } from 'react-intl';
import { getCurrentLocale } from '@/store/sessions/sessions.selectors';
import { getTranslations } from '@/locales';
import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';

export interface AppIntlProviderProps {
  children: React.ReactNode;
}

export const AppIntlProvider: React.FC<AppIntlProviderProps> = ({ children }) => {
  const locale = useSelector(getCurrentLocale);
  const translationsForCurrentLocale = useMemo(() => getTranslations(locale), [locale]);
  return (
    <IntlProvider locale={locale} messages={translationsForCurrentLocale}>
      {children}
    </IntlProvider>
  );
};
