import { DateField } from '@/components/FormBuilder/DateField';
import { LegsField } from '@/components/FormBuilder/LegsField';
import { ListField } from '@/components/FormBuilder/ListField';
import { NumberField } from '@/components/FormBuilder/NumberField';
import { SalesField } from '@/components/FormBuilder/SalesField';
import { ToggleButtonsField } from '@/components/FormBuilder/ToggleButtonsField';
import { UnderlyingsField } from '@/components/FormBuilder/UnderlyingsField';
import type { ColumnSpan, UiElementType } from '@/models';

export function getField(uiElementType?: UiElementType) {
  switch (uiElementType) {
    case 'ToggleButtonsField':
      return <ToggleButtonsField />;
    case 'ListField':
      return <ListField />;
    case 'NumberField':
      return <NumberField />;
    case 'DateField':
      return <DateField />;
    case 'UnderlyingsField':
      return <UnderlyingsField />;
    case 'LegsField':
      return <LegsField />;
    case 'SalesField':
      return <SalesField />;
    default:
      return <span>{`${uiElementType} Not implemented...`}</span>;
  }
}

export function getColumnSpanClass(columnSpan?: ColumnSpan) {
  return columnSpan
    ? `col-xs-${columnSpan?.xs} col-md-${columnSpan?.md} col-xl-${columnSpan?.xl} col-xxl-${columnSpan?.xxl}`
    : `col-xs-12 col-md-6 col-xl-4 col-xxl-3`;
}
