import { FormattedMessage } from 'react-intl';
import { toast } from '@/components/shared/Toast/toast';
import { useObservable } from 'observable-hooks';
import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import type { Toast } from '@/components/shared/Toast/toast';

const StyledDiv = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: calc(100vw - 360px - 1.5rem);
  z-index: 1100;
  margin-top: 56px;
`;

export const ToastsContainer: React.FC = () => {
  const toasts$ = useObservable<Toast[]>(() => toast.getToastsSubject());

  const [toasts, setToasts] = useState<Toast[]>([]);

  const dismissToast = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      toast.dismiss(id);
    },
    [],
  );

  useEffect(() => {
    const sub = toasts$.subscribe(toast => {
      setToasts(toast);
    });
    return () => sub.unsubscribe();
  });

  return !toasts ? null : (
    <StyledDiv>
      {toasts.map(t => (
        <div
          className={classnames(`notification notification-${t.style} fade mb-1`, 'show')}
          data-e2e={`toast-${t.id}`}
          id={`toast-${t.id}`}
          key={t.id}
          role="alert"
        >
          {t.header && (
            <div className="notification-header">
              <span className="fw-medium me-auto">
                <FormattedMessage id={t.header.id} values={t.header.values} />
              </span>
              <button
                aria-label="Close"
                className="btn-close"
                data-dismiss="toast"
                id={`toast-${t.id}-dismiss`}
                onClick={dismissToast(t.id)}
                type="button"
              />
            </div>
          )}
          <div className="notification-body">
            <FormattedMessage id={t.message.id} values={t.message.values} />
          </div>
          {t.footer && (
            <div className="toast-footer">
              <span className="text-secondary fw-medium">
                <FormattedMessage id={t.footer} />
              </span>
            </div>
          )}
        </div>
      ))}
    </StyledDiv>
  );
};
