import type { HttpConfig, HttpRequestMiddleware } from '@/utils/http/httpClient.models';

export const webApiBaseUrlHttpRequestMiddleware: HttpRequestMiddleware = (config: HttpConfig): HttpConfig => {
  if (!config.url.startsWith('/')) {
    return config;
  }

  const appConfig = window.appConfigs;
  return { ...config, url: `${appConfig.webApi.base.slice(0, -1)}${config.url}` };
};
