import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';

export interface CardHeaderProps {
  className?: string;
}

export const CardHeader: FC<PropsWithChildren<CardHeaderProps>> = ({ children, className }) => {
  return <div className={classNames('card-header d-flex p-0', className)}>{children}</div>;
};
