import { type BpEpicDependencies, rootEpic } from '@/store/app.epic';
import { type RootState } from '@/store/app.state';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createEpicMiddleware } from 'redux-observable';
import { createRootReducer } from '@/store/app.reducer';
import thunk from 'redux-thunk';
import type { Actions } from '@/store/app.actions';
import type { PreloadedState, Store } from 'redux';

export const configureStore = (state: PreloadedState<RootState>, withDevTools: boolean): Store<RootState, Actions> => {
  const epicMiddleware = createEpicMiddleware<Actions, Actions, RootState, BpEpicDependencies>();

  const enhancers = applyMiddleware(thunk, epicMiddleware);

  const store = createStore<RootState, Actions, unknown, unknown>(
    createRootReducer(),
    state as PreloadedState<RootState>,
    withDevTools ? composeWithDevTools(enhancers) : enhancers,
  );

  epicMiddleware.run(rootEpic());

  return store;
};
