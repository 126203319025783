/* eslint-disable @typescript-eslint/no-explicit-any */
import { sgwtConnect } from '@/widgets/sgwtConnect';
import type { AjaxResponse } from 'rxjs/ajax';
import type { HttpResponseMiddleware } from '../../httpClient.models';

export const tokenExpiredHttpResponseMiddleware: HttpResponseMiddleware = (
  response: AjaxResponse<any>,
): AjaxResponse<any> => {
  if (response.status === 401 && !sgwtConnect.isAuthorized()) {
    sgwtConnect.requestAuthorization();
  }

  return response;
};
