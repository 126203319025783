import { MultiplePicker } from '@/components/shared/MultiplePicker';
import { SinglePicker } from '@/components/shared/SinglePicker';
import { TextInput } from '@/components/shared/TextInput';
import { getAuctionsStatus } from '@/store/auctions';
import { mapWithTranslation } from '@/utils/mapWithTranslation';
import { useFieldContext } from './FieldContext';
import { useSelector } from 'react-redux';
import type { OptionValue } from '@/models';

export const ListField = () => {
  const { fieldDefinition, values, onPatch } = useFieldContext();
  const { translationKey, availableItems = [], id, isMultipleSelect } = fieldDefinition;

  const { isLoading: isEnrichLoading } = useSelector(getAuctionsStatus('enrich'));

  if (availableItems.length <= 1) {
    return <TextInput label={`fieldId.${translationKey}`} value={availableItems?.[0]?.label} readOnly />;
  }

  return (
    <>
      {isMultipleSelect ? (
        <MultiplePicker
          label={`fieldId.${translationKey}`}
          items={availableItems.map(mapWithTranslation)}
          values={values?.[id] as OptionValue[]}
          onChange={v => v && onPatch({ [id]: v })}
          hideClearButton={true}
          disabled={isEnrichLoading}
        />
      ) : (
        <SinglePicker
          label={`fieldId.${translationKey}`}
          items={availableItems?.map(mapWithTranslation) ?? []}
          value={values?.[id] as OptionValue}
          onSelect={v => v && onPatch({ [id]: v })}
          hideClearButton={true}
          disabled={isEnrichLoading}
        />
      )}
    </>
  );
};
