import classnames from 'classnames';
import type { PdcDetailsUiColorEnum } from '@/models';

interface PdcStatusIconProps {
  uiColor?: PdcDetailsUiColorEnum;
}

const PdcStatusIcon: React.FC<PdcStatusIconProps> = ({ uiColor }) => {
  if (uiColor === 'GREEN' || uiColor === 'GREEN_BLUR') {
    return (
      <i className={classnames('icon icon-sm text-success', { 'text-opacity-40': uiColor === 'GREEN_BLUR' })}>
        check_circle
      </i>
    );
  }
  if (uiColor === 'RED_DEROGATED') {
    return (
      <>
        <i className="icon icon-sm text-success">check_circle</i>
        <i className="icon icon-sm text-primary">flag</i>
      </>
    );
  }
  if (uiColor === 'AMBER' || uiColor === 'AMBER_BLUR') {
    return (
      <i className={classnames('icon icon-sm text-warning', { 'text-opacity-40': uiColor === 'AMBER_BLUR' })}>error</i>
    );
  }
  if (uiColor === 'RED' || uiColor === 'RED_BLUR') {
    return (
      <i className={classnames('icon icon-sm text-danger', { 'text-opacity-40': uiColor === 'RED_BLUR' })}>
        do_not_disturb
      </i>
    );
  }
  if (uiColor === 'UNKNOWN' || uiColor === 'UNKNOWN_BLUR') {
    return (
      <i className={classnames('icon icon-sm text-light', { 'text-opacity-40': uiColor === 'UNKNOWN_BLUR' })}>circle</i>
    );
  }
  return <></>;
};

export default PdcStatusIcon;
