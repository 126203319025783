import type { DerogationReason, Pdc, PdcDerogation, PdcDetails, PdcDetailsUiColorEnum } from '@/models';

export type PdcTypeEnum =
  | 'KYC'
  | 'MIFID1'
  | 'CREDIT_CHECK_BID'
  | 'CREDIT_CHECK_ASK'
  | 'COST_AND_CHARGES'
  | 'EMBARGO_CTPY'
  | 'EMBARGO'
  | 'CASS'
  | 'DFA'
  | 'DFA_SEC'
  | 'EMIR'
  | 'FATCA'
  | 'HIRE_ACT'
  | 'CROSS_BORDER'
  | 'HKMA'
  | 'PTT'
  | 'IM_VM'
  | 'IM_SIMULATION_BID'
  | 'IM_SIMULATION_ASK';

export const pdcTypes: PdcType[] = [
  { id: 'KYC', label: 'KYC' },
  { id: 'MIFID1', label: 'MIFID' },
  { id: 'CREDIT_CHECK_BID', label: 'Risk Bid' },
  { id: 'CREDIT_CHECK_ASK', label: 'Risk Ask' },
  { id: 'COST_AND_CHARGES', label: 'MIFID 2 C&C' },
  { id: 'EMBARGO_CTPY', label: 'Embargo Ctp.' },
  { id: 'EMBARGO', label: 'Embargo Ins.' },
  { id: 'DFA', label: 'DFA CFTC' },
  { id: 'DFA_SEC', label: 'DFA SEC' },
  { id: 'EMIR', label: 'EMIR' },
  { id: 'FATCA', label: 'FATCA' },
  { id: 'HIRE_ACT', label: 'HIRE Act' },
  { id: 'CROSS_BORDER', label: 'X-Border' },
  { id: 'HKMA', label: 'HKMA' },
  { id: 'PTT', label: 'PTT' },
  { id: 'IM_VM', label: 'IM/VM' },
  { id: 'IM_SIMULATION_BID', label: 'UMR RFQ Bid' },
  { id: 'IM_SIMULATION_ASK', label: 'UMR RFQ Ask' },
];

export type PdcDetailsResponse = {
  entries: PdcDetails | null;
};

export type PdcType = {
  id: PdcTypeEnum;
  label: string;
};

export function computePdcStatusForSalesCounterparty(
  type: PdcType | undefined,
  pdcs: Pdc[] | undefined,
  strategyIds: (string | undefined)[] | undefined,
): PdcDetailsUiColorEnum | undefined {
  if (type === undefined || pdcs === undefined || strategyIds === undefined) {
    return undefined;
  }

  const allStrategyUiColor = strategyIds
    .filter(strategyId => strategyId !== undefined)
    .map(strategyId =>
      computePdcStatusForStrategy(
        type,
        pdcs.filter(pdc => pdc.strategyUuid === strategyId && pdc.level === 'ALLOCATION'),
      ),
    );
  return prioritizePdcStatus(allStrategyUiColor);
}

export function computePdcStatusForStrategy(
  type: PdcType | undefined,
  pdcs: Pdc[] | undefined,
): PdcDetailsUiColorEnum | undefined {
  if (type === undefined || pdcs === undefined) {
    return undefined;
  }

  const uiColorOfNonLegPdc = pdcs?.find(pdc => pdc.type === type.id && pdc.legUuid === undefined)?.uiColor;

  if (uiColorOfNonLegPdc !== undefined) {
    return uiColorOfNonLegPdc;
  }

  const uiColorsOfAllLegs = pdcs?.filter(pdc => pdc.type === type.id)?.map(pdc => pdc.uiColor);
  return prioritizePdcStatus(uiColorsOfAllLegs);
}

export function prioritizePdcStatus(
  uiColors: (PdcDetailsUiColorEnum | undefined)[] | undefined,
): PdcDetailsUiColorEnum | undefined {
  if (uiColors === undefined) {
    return undefined;
  }

  const uiColorPriority: PdcDetailsUiColorEnum[] = [
    'UNKNOWN_BLUR',
    'UNKNOWN',
    'RED_BLUR',
    'RED',
    'AMBER_BLUR',
    'AMBER',
    'RED_DEROGATED',
    'GREEN_BLUR',
    'GREEN',
  ];

  let result;
  for (let i = 0; i <= uiColorPriority.length - 1; i++) {
    if (uiColors.includes(uiColorPriority[i])) {
      result = uiColorPriority[i];
      break;
    }
  }
  return result;
}

export function getDerogationReasonsByType(
  pdcDerogation: PdcDerogation,
  typeId: PdcTypeEnum,
): DerogationReason[] | undefined {
  if (pdcDerogation?.reasons !== undefined) {
    return pdcDerogation.reasons[typeId];
  }

  return undefined;
}
