import { ServiceLoader } from '@sgme/ui';
import { isLoadingUserInfo } from '@/store/users/users.selectors';
import { useSelector } from 'react-redux';
import type { FC, PropsWithChildren } from 'react';

export const SgmeServiceLoader: FC<PropsWithChildren> = ({ children }) => {
  const isLoading = useSelector(isLoadingUserInfo);

  return <ServiceLoader isAppLoading={isLoading}>{children}</ServiceLoader>;
};
