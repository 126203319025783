import { GoCurrentPageButton } from '@/components/shared/GoBackButton';
import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';

export interface NavHeaderProps {
  showBackButton?: boolean;
  withTabs?: boolean;
}

export const NavHeader: FC<PropsWithChildren<NavHeaderProps>> = ({ children, showBackButton, withTabs = false }) => {
  return (
    <div
      className={classNames('d-flex flex-row justify-content-between align-items-center', withTabs ? 'mb-3' : 'mb-4')}
    >
      {showBackButton && <GoCurrentPageButton size="lg" arrowOnly />}
      {children}
    </div>
  );
};
