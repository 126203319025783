import { DatePicker } from '@/components/shared/DatePicker';
import { format } from 'date-fns';
import { getAuctionsStatus } from '@/store/auctions';
import { useFieldContext } from './FieldContext';
import { useSelector } from 'react-redux';

export const DateField = () => {
  const { fieldDefinition, values, onPatch } = useFieldContext();
  const { translationKey, id, startDate } = fieldDefinition;

  const { isLoading: isEnrichLoading } = useSelector(getAuctionsStatus('enrich'));

  return (
    <DatePicker
      label={`fieldId.${translationKey}`}
      value={values?.[id] as string | undefined}
      onChange={v => id && onPatch({ [id]: v === undefined ? null : format(v, 'yyyy-MM-dd') })}
      hideClearButton={fieldDefinition.hideClearButton}
      minValue={startDate}
      isDisabled={isEnrichLoading}
    />
  );
};
