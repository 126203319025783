import { FieldWrapper } from '@/components/shared/FieldWrapper';
import { NumericFormat } from 'react-number-format';
import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import type { IntlKey } from '@/locales';

export interface NumberInputProps {
  className?: string;
  label: IntlKey;
  value?: number;
  placeholder?: IntlKey;
  unit?: string;
  readOnly?: boolean;
  disableDecimals?: boolean;
  nbDecimals?: number;
  maxLength?: number;
  thousandSeparator?: string;
  enableShortcuts?: boolean;
  onBlur?: (value?: number) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

export const NumberInput: FC<NumberInputProps> = ({
  label,
  className,
  onBlur,
  onChange,
  value,
  readOnly = false,
  placeholder = 'Enter',
  unit,
  disableDecimals = false,
  nbDecimals,
  maxLength,
  thousandSeparator = ',',
  enableShortcuts = true,
}) => {
  const [inputValue, setInputValue] = useState<typeof value>(value);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const disableDecimalInput = disableDecimals && e.key === '.';
      if (disableDecimalInput) {
        e.preventDefault();
      } else {
        setInputValue(v => (enableShortcuts ? applyNumberShortcuts(e.key, v) : v));
      }
    },
    [disableDecimals, enableShortcuts],
  );

  useEffect(() => setInputValue(value), [value]);

  return (
    <FieldWrapper label={label} className={className}>
      <div className="input-group">
        <NumericFormat
          className="form-control"
          value={inputValue ?? ''}
          placeholder={readOnly ? '-' : placeholder}
          readOnly={readOnly}
          decimalScale={nbDecimals}
          fixedDecimalScale
          maxLength={maxLength}
          onValueChange={({ floatValue }) => setInputValue(floatValue)}
          onBlur={() => onBlur && inputValue !== value && onBlur(inputValue)}
          onKeyDown={handleKeyDown}
          thousandSeparator={thousandSeparator}
          onChange={onChange}
        />
        {unit && <span className="input-group-text">{unit}</span>}
      </div>
    </FieldWrapper>
  );
};

function applyNumberShortcuts(shortcut: string, value: number | undefined) {
  if (value === undefined) {
    return undefined;
  }

  switch (shortcut) {
    case 'k':
      return value * 1000;
    case 'm':
      return value * 1000000;
    case 'g':
      return value * 1000000000;
    case 'b':
      return value * 1000000000;
    default:
      return value;
  }
}
