import { Card, CardBody, CardHeader, CardTitle } from '@/components/shared/Card';
import { FormattedNumber } from '@/components/shared/FormattedNumber';
import { FormattedText } from '@/components/shared/FormattedText';
import { Table, Tbody, Td, Th, Thead } from '@/components/shared/Table';
import { formatNumberWithDecimals } from '@/utils/string';
import { isDefined } from '@sgme/fp';
import type { Auction } from '@/models';
import type { FC } from 'react';

export interface ExecutionDetailsProps {
  auction?: Auction;
  className?: string;
}

export const ExecutionDetails: FC<ExecutionDetailsProps> = ({ auction, className }) => {
  const executionDetailsDealer = auction?.rfqDealers?.find(d => d.executedQuote === true);
  const executionDetailsInstrument = auction?.auctionBaseRfq?.instruments?.at(0);
  const executionDetailsInstrumentMasterLeg = auction?.auctionBaseRfq?.instruments
    ?.at(0)
    ?.instrumentLegs?.find(i => i.master === true);
  const executionDetailsInstrumentName = `${executionDetailsInstrument?.instrumentType} ${executionDetailsInstrumentMasterLeg?.underlying?.bloombergCode}`;
  return (
    <Card padding={3} className={className}>
      <CardHeader>
        <CardTitle size={6}>
          <FormattedText id="executionDetails.title" />
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Table className="table-sm w-100 m-0">
          <Thead>
            <tr>
              <FormattedText as={Th} id="executionDetails.dealer" className="text-secondary" />
              <FormattedText as={Th} id="executionDetails.instrument" className="text-secondary" />
              <FormattedText
                as={Th}
                id="executionDetails.quantity"
                className="text-secondary"
                style={{ textAlign: 'right' }}
              />
              <FormattedText
                as={Th}
                id="executionDetails.price"
                className="text-secondary"
                style={{ textAlign: 'right' }}
              />
              <FormattedText as={Th} id="executionDetails.bookingPortfolio" className="text-secondary" />
              <FormattedText as={Th} id="executionDetails.bookingCentre" className="text-secondary" />
              <FormattedText as={Th} id="executionDetails.markitWireId" className="text-secondary" />
            </tr>
          </Thead>
          <Tbody>
            <tr>
              <FormattedText as={Td} text={executionDetailsDealer?.salesCounterparty?.name} className="fw-bold" />
              <FormattedText as={Td} text={executionDetailsInstrumentName} />
              <FormattedNumber
                as={Td}
                className="fw-semibold"
                value={executionDetailsInstrumentMasterLeg?.negotiatedSize?.quantity}
                align="right"
              />
              <div style={{ textAlign: 'right' }}>
                <span className="fw-semibold">
                  {isDefined(executionDetailsDealer?.price?.value)
                    ? formatNumberWithDecimals(executionDetailsDealer!.price!.value!, 4)
                    : ''}
                </span>
                <span className="ms-1 text-secondary">{executionDetailsDealer?.price?.unit}</span>
              </div>
              <FormattedText as={Td} text={executionDetailsInstrument?.bookingPortfolio} />
              <FormattedText as={Td} text={executionDetailsInstrument?.bookingCenter} />
              <FormattedText as={Td} text={auction?.markitWireId} />
            </tr>
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
