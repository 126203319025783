import { type FC, Fragment } from 'react';
import { NumberInput } from '@/components/shared/NumberInput';
import { getAuctionsStatus } from '@/store/auctions';
import { getColumnSpanClass } from '@/components/FormBuilder/FormBuilder.helper';
import { useFieldContext } from '@/components/FormBuilder/FieldContext';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';

interface LegHeaderProps {
  className?: string;
  label?: string;
}

interface Leg {
  ratio: number;
  strike?: number;
}

const LegHeader: FC<LegHeaderProps> = ({ className, label }) => {
  return <span className={classNames('fw-bold', className)}>{label}&nbsp;</span>;
};

export const LegsField = () => {
  const { fieldDefinition, values, onPatch } = useFieldContext();
  const { id, subFieldDefinition } = fieldDefinition;

  const { isLoading: isEnrichLoading } = useSelector(getAuctionsStatus('enrich'));

  const legs = (values?.[id] ?? []) as Leg[];
  const hasMultipleLegs = legs.length > 1;

  return (
    <div className="row">
      {subFieldDefinition?.legs?.map(({ item1, item2 }, i) => (
        <Fragment key={`${id}-leg-${i}`}>
          <div
            className={classNames(
              'mb-6',
              getColumnSpanClass(item2?.columnSpan),
              item1?.isHidden ? 'd-none' : 'd-block',
            )}
          >
            {hasMultipleLegs && <LegHeader label={`Leg ${i + 1}`} />}
            <NumberInput
              label="Ratio"
              value={legs[i]?.ratio}
              readOnly={item1?.isReadOnly || isEnrichLoading}
              nbDecimals={item1?.nbDecimals}
              onBlur={v => id && onPatch({ [id]: _.set(legs, `[${i}].ratio`, v ?? null) })}
            />
          </div>
          <div
            className={classNames(
              'mb-6',
              getColumnSpanClass(item2?.columnSpan),
              item2?.isHidden ? 'd-none' : 'd-block',
            )}
          >
            {hasMultipleLegs && <LegHeader label="" />}
            <NumberInput
              label="Strike"
              unit={item2?.unit}
              readOnly={item2?.isReadOnly || isEnrichLoading}
              value={legs[i]?.strike}
              nbDecimals={item2?.nbDecimals}
              onBlur={v => id && onPatch({ [id]: _.set(legs, `[${i}].strike`, v ?? null) })}
            />
          </div>
        </Fragment>
      ))}
    </div>
  );
};
