import { type FC, useCallback } from 'react';
import { FieldWrapper } from '@/components/shared/FieldWrapper';
import { SgTimepicker } from '@sg-bootstrap/components/dist/react-library/src/components';
import type { IntlKey } from '@/locales';
import type { PickerState, TimeEventData } from '@sg-bootstrap/components';

export interface TimePickerProps {
  label: IntlKey;
  className?: string;
  value?: string;
  onChange?: (time?: string) => void;
  isDisabled?: boolean;
  validationMessage?: string;
  state?: PickerState;
  noClearButton?: boolean;
}

export const TimePicker: FC<TimePickerProps> = ({
  label,
  className,
  value,
  onChange,
  isDisabled,
  validationMessage,
  state,
  noClearButton = true,
}) => {
  const onTimeSelected = useCallback(
    ({ detail: { time } }: CustomEvent<TimeEventData>) => {
      if (time === undefined || onChange === undefined) {
        return;
      }

      onChange(time);
    },
    [onChange],
  );

  const onInputCleared = useCallback(() => {
    if (onChange === undefined) {
      return;
    }

    onChange(undefined);
  }, [onChange]);
  return (
    <>
      <FieldWrapper label={label} className={className}>
        <SgTimepicker
          onTimeSelected={onTimeSelected}
          value={value}
          disabled={isDisabled}
          onInputCleared={onInputCleared}
          state={state}
          validationMessage={validationMessage}
          noClearButton={noClearButton}
        />
      </FieldWrapper>
    </>
  );
};
