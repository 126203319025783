import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'reactstrap';
import { useToggle } from '../../../hooks/useToggle/useToggle';
import React, { useRef } from 'react';
import type { Color } from '@/models';
import type { IntlKey } from '@/locales';

export type TooltipPosition = 'auto' | 'top' | 'left' | 'right' | 'bottom';

export interface WithTooltipProps {
  children: React.ReactNode;
  className?: string;
  position?: TooltipPosition;
  value: IntlKey;
  color?: Color;
  active?: boolean;
}

export const WithTooltip: React.FC<WithTooltipProps> = ({
  children,
  className,
  position = 'auto',
  color = 'primary',
  value,
  active = true,
}) => {
  const { isOn, toggle } = useToggle(false);
  const wrapper = useRef<HTMLDivElement>(null);

  if (!active) {
    return <>{children}</>;
  }

  return (
    <>
      <span ref={wrapper} className={className}>
        {children}
      </span>
      <Tooltip className={`tooltip-${color}`} isOpen={isOn} placement={position} toggle={toggle} target={wrapper}>
        <FormattedMessage id={value} />
      </Tooltip>
    </>
  );
};
