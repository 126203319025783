import { Collapse } from 'reactstrap';
import { useFoldableCardContext } from '@/components/shared/FoldableCard/FoldableCardContext';
import type { FC, PropsWithChildren } from 'react';

export const FoldableCardBody: FC<PropsWithChildren> = ({ children }) => {
  const { open } = useFoldableCardContext();
  return (
    <Collapse tag="div" className="card card-body bg-lvl2 border-top p-3" isOpen={open}>
      {children}
    </Collapse>
  );
};
