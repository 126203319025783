import { FormattedPlural } from '@/components/shared/FormattedPlural';
import { FormattedText } from '@/components/shared/FormattedText';
import { Icon } from '@sgme/ui';
import React from 'react';
import classNames from 'classnames';
import type { FC } from 'react';

interface DealersSelectionBannerProps {
  selectedDealersCount: number;
  onReselectDealersClicked: () => void;
  onSaveSelectionClicked: () => void;
  isSaveSelectionBtnDisabled: boolean;
  isDealersSelected: boolean;
}

export const DealersSelectionBanner: FC<DealersSelectionBannerProps> = ({
  selectedDealersCount,
  onReselectDealersClicked,
  onSaveSelectionClicked,
  isSaveSelectionBtnDisabled,
  isDealersSelected,
}) => {
  return (
    <div
      className={classNames(
        'alert mb-2 d-flex align-items-center justify-content-between',
        isDealersSelected ? 'alert-discreet-info' : 'alert-info',
      )}
      style={{ height: 65 }}
    >
      <div className="align-items-center">
        {isDealersSelected && <Icon icon={'info'} className="me-2 align-self-start" />}
        <span className={classNames(isDealersSelected ? '' : 'fw-bold')}>
          {selectedDealersCount} <FormattedPlural value={selectedDealersCount} one="dealer" other="dealers" />{' '}
        </span>
        <span>
          <FormattedText id="selected for bidding." />{' '}
          {!isDealersSelected && <FormattedText id="Please save your selection to confirm." />}
        </span>
      </div>
      {isDealersSelected ? (
        <button className="btn btn-md btn btn-outline-info " onClick={onReselectDealersClicked}>
          <FormattedText id="Reselect dealers" />
        </button>
      ) : (
        <button
          className="btn btn-md btn-outline-primary-alt btn-info"
          onClick={onSaveSelectionClicked}
          disabled={isSaveSelectionBtnDisabled}
        >
          <FormattedText id="Save selection" />
        </button>
      )}
    </div>
  );
};
