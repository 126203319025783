import { CURRENT_USER_INFO_REQUESTED, currentUserInfoFetchFailed } from '@/store/users/users.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { currentUserInfoFetchedThunk } from '@/store/users/users.thunks';
import { httpClient } from '@/utils/http/httpClient';
import { usersApiConnector } from '@/api/users';
import type { Epic } from 'redux-observable';
import type { ErrorResponse } from '@/models/Responses/ErrorResponse';
import type { UsersApi } from '@/api/users';

const usersApi$ = usersApiConnector(httpClient);

export const currentUserInfoRequestedEpic =
  (api$: UsersApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(CURRENT_USER_INFO_REQUESTED),
      switchMap(() =>
        api$.getCurrentUserInfo().pipe(
          map(user => currentUserInfoFetchedThunk(user)),
          catchError((error: ErrorResponse) => of(currentUserInfoFetchFailed(error))),
        ),
      ),
    );

export const usersEpics = () => combineEpics(currentUserInfoRequestedEpic(usersApi$));
