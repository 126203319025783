import type { IntlFormatters } from 'react-intl';
import type { OptionItem, OptionValue } from '@/models';

export function toPickerItems(formatMessage: IntlFormatters['formatMessage'], items: OptionItem[]) {
  return items.map(({ label, translationKey, value }) => ({
    key: toPickerKey(value),
    label: translationKey ? formatMessage({ id: translationKey }) : label,
    value,
  }));
}

export function toPickerKey<T extends OptionValue>(value: T): string | number {
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'number') {
    return value.toString();
  }
  if (typeof value === 'boolean') {
    return value ? 'true' : 'false';
  }
  return value.id;
}
