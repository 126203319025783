import { AppMain } from '@/components/AppMain';
import { AppNav, NavActions, NavHeader, NavTitle } from '@/components/AppNav';
import { Button } from '@sgme/ui';
import { EnrichForm } from '@/components/EnrichForm';
import { FormattedText } from '@/components/shared/FormattedText';
import { GoCurrentPageButton } from '@/components/shared/GoBackButton';
import {
  createAuctionQueued,
  createAuctionRequested,
  getAuctionsStatus,
  getIsButtonDisabled,
  initStrategyFormRequested,
} from '@/store/auctions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useRedirect } from '@/hooks/useRedirect';

export const CreatePage = () => {
  const dispatch = useDispatch();
  const { isLoading: isCreateLoading, response } = useSelector(getAuctionsStatus('create'));
  const { isLoading: isEnrichLoading, isInit: isInitializing } = useSelector(getAuctionsStatus('enrich'));
  const isCreateButtonDisabled = useSelector(getIsButtonDisabled('CreateAuction'));

  useRedirect({ to: '/auctions/current', when: response !== undefined });

  useEffect(() => {
    dispatch(initStrategyFormRequested('create', 'CALL'));
  }, [dispatch]);

  return (
    <>
      <AppNav>
        <NavHeader showBackButton>
          <FormattedText id="New auction details" as={NavTitle} />
          <NavActions>
            <GoCurrentPageButton size="lg" label="Cancel" />
            <FormattedText
              id={isEnrichLoading ? 'Validating your inputs' : 'Create auction'}
              as={Button}
              className="ms-2"
              disabled={isCreateLoading || isInitializing || isEnrichLoading || isCreateButtonDisabled}
              loading={isCreateLoading || isEnrichLoading || isInitializing}
              loadingSpinnerPosition="end"
              purpose="primary"
              color="primary"
              size="lg"
              onClick={() => dispatch(createAuctionRequested())}
              onMouseDown={() => dispatch(createAuctionQueued())}
            />
          </NavActions>
        </NavHeader>
      </AppNav>
      <AppMain>
        <EnrichForm page="create" readonly={isCreateLoading || isEnrichLoading} />
      </AppMain>
    </>
  );
};
