import { Modal } from '@/components/shared/Modal';
import { cancelAuctionRequested } from '@/store/auctions';
import {
  closeCancelModal,
  getCancelAuctionStrategy,
  getCancelAuctionUuid,
  getCancelModalConfirmationDisabled,
} from '@/store/ui';
import { useDispatch, useSelector } from 'react-redux';
import type { ModalProps } from '@/components/shared/Modal';

interface CancelModalProps extends ModalProps {
  initialDisplayed?: boolean;
}

const CancelModal: React.FC<CancelModalProps> = ({ initialDisplayed }) => {
  const dispatch = useDispatch();

  const auctionUuid = useSelector(getCancelAuctionUuid());
  const strategy = useSelector(getCancelAuctionStrategy());
  const confirmationDisabled = useSelector(getCancelModalConfirmationDisabled());

  function onConfirm() {
    if (auctionUuid !== undefined) {
      dispatch(cancelAuctionRequested(auctionUuid));
    }
  }
  return (
    <Modal
      initialDisplayed={initialDisplayed}
      id="cancel-modal"
      title={`Confirm cancellation`}
      bordered
      minWidth={600}
      color={'warning'}
      children={
        <>
          <p>
            Are you sure you want to cancel the auction <span className="fw-bold">{strategy}</span>? This action is
            irreversible.
          </p>
        </>
      }
      onClose={() => dispatch(closeCancelModal())}
      onCancel={() => dispatch(closeCancelModal())}
      confirmActionDisabled={auctionUuid === undefined || confirmationDisabled}
      confirmActionLabelKey={'Yes, cancel auction'}
      onConfirm={() => onConfirm()}
      loading={confirmationDisabled}
    />
  );
};

export default CancelModal;
