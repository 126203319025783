import { AccountCenter } from '@/components/sgwt/AccountCenter';

export function AppHeader(): JSX.Element {
  return (
    <header className="navbar navbar-sm border-bottom-0">
      <div className="navbar-title">
        <a className="navbar-title-link" href="/">
          <div className="navbar-logo">
            <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0" y="0" width="32" height="32" fill="white" />
              <rect x="1" y="1" width="30" height="15" fill="var(--bs-socgen)" />
              <rect x="1" y="16" width="30" height="15" fill="black" />
              <rect x="6" y="15" width="20" height="2" fill="white" />
            </svg>
          </div>
          <div className="navbar-title-divider" />
          <div className="navbar-service-name">Bidding</div>
        </a>
      </div>

      <div className="navbar-toolbar">
        <AccountCenter />
      </div>
    </header>
  );
}
