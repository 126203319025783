import { SgwtWidgetsContext } from '@/contexts/SgwtWidgets/SgwtWidgetsContext';
import { useAppConfigs } from '@/hooks/useAppConfigs/useAppConfigs';
import { useContext, useEffect, useRef } from 'react';
import type { FC } from 'react';
import type { SgwtHelpCenterHTMLElement } from '@/types/sgwt-widgets';

export const HelpCenter: FC = () => {
  const {
    sgwtServices: { applicationId },
  } = useAppConfigs();

  const sgwtWidgets = useContext(SgwtWidgetsContext);
  const ref = useRef<SgwtHelpCenterHTMLElement>();
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    sgwtWidgets.setHelpCenter(ref.current);
  }, [sgwtWidgets]);

  return (
    <aside>
      <sgwt-help-center
        allow-screenshot
        application-id={applicationId}
        // @ts-expect-error
        ref={ref}
        sg-connect-support="sg-connect-v2"
      />
    </aside>
  );
};
