import type { Locale } from '@/locales';
import type { SgBootstrapTheme } from '@/theme';

export type SessionsActions = SgConnectTokenExpired | LocaleChanged | ThemeInitialized | ThemeChanged;

export const SG_CONNECT_TOKEN_EXPIRED = '[SG Connect API] Token expired';
export const sgConnectTokenExpired = () => ({ type: SG_CONNECT_TOKEN_EXPIRED }) as const;
export type SgConnectTokenExpired = ReturnType<typeof sgConnectTokenExpired>;

export const LOCALE_CHANGED = '[Locale] Changed';
export const localeChanged = (locale: Locale) => ({ type: LOCALE_CHANGED, locale }) as const;
export type LocaleChanged = ReturnType<typeof localeChanged>;

export const THEME_INITIALIZED = '[Theme] Initialized';
export const themeInitialized = (theme?: SgBootstrapTheme) => ({ type: THEME_INITIALIZED, theme }) as const;
export type ThemeInitialized = ReturnType<typeof themeInitialized>;

export const THEME_CHANGED = '[Theme] Changed';
export const themeChanged = (theme: SgBootstrapTheme) => ({ type: THEME_CHANGED, theme }) as const;
export type ThemeChanged = ReturnType<typeof themeChanged>;
