import { ErrorState } from '@/components/shared/ErrorState';

export const ForbiddenPage = () => {
  return (
    <main className="app-main container d-flex">
      <ErrorState
        color="secondary"
        icon="lock_person"
        messages={[
          'You are forbidden to access to the page requested,',
          'please contact us if you believe this is a mistake.',
        ]}
        showContactUsButton
        showGoBackButton
        title="Access denied"
      />
    </main>
  );
};
