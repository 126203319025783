import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { type FC, type PropsWithChildren, useCallback, useState } from 'react';
import { Icon } from '@sgme/ui';
import type { DropdownToggleProps } from 'reactstrap';
import type { IconProps } from '@sgme/ui';

export interface DropdownButtonProps {
  className?: string;
  color?: DropdownToggleProps['color'];
  end?: DropdownToggleProps['end'];
  icon: string;
  size?: IconProps['size'];
  menuAlignToRight?: boolean;
}

export const DropdownButton: FC<PropsWithChildren<DropdownButtonProps>> = ({
  children,
  className,
  color,
  end,
  icon,
  size,
  menuAlignToRight,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => {
    setIsOpen(value => !value);
  }, [setIsOpen]);
  return (
    <Dropdown className={className} isOpen={isOpen} toggle={toggle} direction="down">
      <DropdownToggle color={color} size={size}>
        <Icon icon={icon} size={size} />
      </DropdownToggle>
      <DropdownMenu end={end} right={menuAlignToRight}>
        {children}
      </DropdownMenu>
    </Dropdown>
  );
};
