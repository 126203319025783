import { v4 as uuidv4 } from 'uuid';
import type { HttpConfig, HttpRequestMiddleware } from '../../httpClient.models';

export const correlationIdHttpRequestMiddleware: HttpRequestMiddleware = (config: HttpConfig): HttpConfig => ({
  ...config,
  headers: {
    ...(config.headers ?? {}),
    'x-correlation-id': uuidv4(),
  },
});
