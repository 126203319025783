import { Button } from '@sgme/ui';
import { type FC, type PropsWithChildren, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import type { ButtonProps } from '@sgme/ui';
import type { RoutePath } from '@/store/router';

export interface NavButtonProps {
  className?: string;
  to: RoutePath;
  purpose?: ButtonProps['purpose'];
  color?: ButtonProps['color'];
  size?: ButtonProps['size'];
  iconPosition?: ButtonProps['iconPosition'];
  onClick?: () => void;
}

export const NavButton: FC<PropsWithChildren<NavButtonProps>> = ({
  children,
  className,
  to,
  purpose,
  color,
  size = 'lg',
  iconPosition,
  onClick,
}) => {
  const navigate = useNavigate();
  const goToPage = useCallback(() => {
    if (onClick) {
      onClick();
    }
    navigate(to);
  }, [navigate, onClick, to]);

  return (
    <Button
      purpose={purpose}
      color={color}
      size={size}
      iconPosition={iconPosition}
      className={className}
      onClick={goToPage}
    >
      {children}
    </Button>
  );
};
