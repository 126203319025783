import { FormattedText } from '@/components/shared/FormattedText';
import { Icon } from '@sgme/ui';
import type { Color } from '@/models';
import type { FC } from 'react';
import type { IntlKey } from '@/locales';

export interface EmptyStateProps {
  color?: Color;
  icon?: string;
  id?: string;
  messages?: IntlKey[];
  title?: IntlKey;
}

export const EmptyState: FC<EmptyStateProps> = ({
  color = 'secondary',
  icon = 'search',
  id,
  messages = [],
  title = 'No result found',
}) => (
  <div id={id} className={`empty-state flex-fill text-${color} text-center mx-auto my-128px`} style={{ maxWidth: 450 }}>
    <Icon icon={icon} size="xl" />
    <FormattedText as="h4" id={title} />
    {messages.map(msg => (
      <FormattedText as="p" id={msg} key={msg} />
    ))}
  </div>
);
