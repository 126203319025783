import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';

export interface CardTitleProps {
  className?: string;
  size?: 4 | 6;
}

export const CardTitle: FC<PropsWithChildren<CardTitleProps>> = ({ children, className, size = 4 }) => {
  return (
    <div className={classNames('card-title mb-12px', className)}>
      {size === 4 && <h4>{children}</h4>}
      {size === 6 && <h6>{children}</h6>}
    </div>
  );
};
