import { FieldWrapper } from '@/components/shared/FieldWrapper';
import { toPickerItems, toPickerKey } from '@/utils/picker';
import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import type { IntlKey } from '@/locales';
import type { OptionItem, OptionValue } from '@/models/picker';
import type { SgPicker, SgPickerChangeEvent } from '@/types/WebComponents';
import type { Size } from '@sgme/ui';

export interface SinglePickerProps<T extends OptionValue> {
  className?: string;
  e2e?: string;
  hideClearButton?: boolean;
  id?: string;
  isInvalid?: boolean;
  items?: OptionItem[];
  label: IntlKey;
  maxDisplayedItems?: number;
  onSelect?: (value: T | undefined) => void;
  onSearch?: (value?: string) => void;
  placeholder?: IntlKey;
  size?: Size;
  value?: T;
  disabled?: boolean;
  icon?: string;
  onBlurEnabled?: boolean;
}

export function SinglePicker<T extends OptionValue>(props: SinglePickerProps<T>) {
  const {
    className,
    e2e,
    hideClearButton = false,
    id,
    isInvalid = false,
    items = [],
    label,
    maxDisplayedItems = 0,
    onSelect: onChange,
    onSearch,
    placeholder = 'Select',
    size = 'md',
    value,
    icon,
    disabled = false,
    onBlurEnabled = true,
  } = props;
  const picker = useRef<SgPicker>(null);
  const selectedInput = useRef('');
  const { formatMessage } = useIntl();

  const onSelectItem = useCallback(
    (event: SgPickerChangeEvent<T>) => {
      const { key, label, item } = event.detail;
      selectedInput.current = label;

      const currenKey = value ? toPickerKey(value) : undefined;
      const selectKey = key;

      if (onChange && currenKey !== selectKey) {
        onChange(item.value);
      }
    },
    [value, onChange],
  );

  const onInputChanged = useCallback(
    (input: CustomEvent<string>) => {
      if (onSearch && input) {
        onSearch(input.detail);
      }
    },
    [onSearch],
  );
  const onClearItem = useCallback(() => {
    if (!hideClearButton && onChange) {
      picker.current.resetState();
      selectedInput.current = '';
      onChange(undefined);
    }
  }, [hideClearButton, onChange]);

  const onBlurInput = useCallback(() => {
    if (onBlurEnabled) {
      picker.current?.setValue(selectedInput.current);
    }
  }, [onBlurEnabled]);

  useEffect(() => {
    picker.current?.setItems(toPickerItems(formatMessage, items));
    if (value) {
      picker.current?.selectItemByKey(toPickerKey(value));
    }
  }, [formatMessage, items, value]);

  useEffect(() => {
    const ref = picker.current;
    if (ref) {
      ref.addEventListener('selectItem', onSelectItem as EventListener);
      ref.addEventListener('inputChanged', onInputChanged as EventListener);
      ref.addEventListener('clear', onClearItem as EventListener);
      ref.addEventListener('blurInput', onBlurInput as EventListener);
    }
    return () => {
      if (ref) {
        ref.removeEventListener('selectItem', onSelectItem as EventListener);
        ref.removeEventListener('inputChanged', onInputChanged as EventListener);
        ref.removeEventListener('clear', onClearItem as EventListener);
        ref.removeEventListener('blurInput', onBlurInput as EventListener);
      }
    };
  }, [picker, onSelectItem, onClearItem, onInputChanged, onBlurInput]);

  return (
    <FieldWrapper label={label} className={className}>
      <div className="d-flex align-items-center">
        <sg-picker
          disabled={disabled}
          data-e2e={`${e2e}-picker`}
          input-id={id}
          max-displayed-items={maxDisplayedItems}
          no-clear={hideClearButton || value === undefined}
          icon={icon}
          placeholder={formatMessage({ id: placeholder })}
          ref={picker}
          single-select
          size={size}
          state={isInvalid ? 'invalid' : undefined}
          no-typing-hint-message
          hide-no-result-message
          no-icon={icon === undefined}
        />
      </div>
    </FieldWrapper>
  );
}
