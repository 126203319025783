import { SgwtWidgetsContext } from '@/contexts/SgwtWidgets/SgwtWidgetsContext';
import { getCurrentLocale } from '@/store/sessions/sessions.selectors';
import { localeChanged, themeChanged } from '@/store/sessions/sessions.actions';
import { trackMatomoEvent } from '@/Matomo';
import { useAppConfigs } from '@/hooks/useAppConfigs/useAppConfigs';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { FC } from 'react';
import type { SgwtAccountCenterHTMLElement } from '@/types/sgwt-widgets';

const signOutEventHandler = (): void => {
  window.location.href = window.appConfigs.signOutUrl;
};

export const AccountCenter: FC = () => {
  const {
    availableLanguages,
    environment: { label: envLabel },
    sgwtServices: { partialIncidentProducerCode },
  } = useAppConfigs();
  const dispatch = useDispatch();

  const lang = useSelector(getCurrentLocale);
  const availableLanguagesConfig = useMemo(() => availableLanguages.join(','), [availableLanguages]);

  const sgwtWidgets = useContext(SgwtWidgetsContext);
  const ref = useRef<SgwtAccountCenterHTMLElement>();
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    sgwtWidgets.setAccountCenter(ref.current);
  }, [sgwtWidgets]);

  const languageChangedEventHandler = useCallback(
    (event: any) => {
      dispatch(localeChanged((event as CustomEvent).detail.language));
    },
    [dispatch],
  );

  const themeChangedEventHandler = useCallback(
    (event: any) => {
      const sgwtTheme = event.detail.theme;
      const newBootstrapTheme = sgwtTheme === 'dark' ? 'DARK' : 'STANDARD';
      dispatch(themeChanged(newBootstrapTheme));
      trackMatomoEvent('switchTheme', newBootstrapTheme === 'DARK' ? 'Dark theme' : 'Standard theme');
    },
    [dispatch],
  );

  useEffect(() => {
    const accountCenterRef = document.querySelector('sgwt-account-center');
    if (accountCenterRef) {
      accountCenterRef.addEventListener('sgwt-account-center--sign-out', signOutEventHandler);
      accountCenterRef.addEventListener('sgwt-account-center--language-changed', languageChangedEventHandler);
      accountCenterRef.addEventListener('sgwt-account-center--theme-changed', themeChangedEventHandler);
    }
    return () => {
      if (accountCenterRef) {
        accountCenterRef.removeEventListener('sgwt-account-center--sign-out', signOutEventHandler);
        accountCenterRef.removeEventListener('sgwt-account-center--language-changed', languageChangedEventHandler);
        accountCenterRef.removeEventListener('sgwt-account-center--theme-changed', themeChangedEventHandler);
      }
    };
  }, [languageChangedEventHandler, themeChangedEventHandler]);

  return (
    <sgwt-account-center
      authentication="sg-connect-v2"
      available-languages={availableLanguagesConfig}
      environment={envLabel}
      language={lang}
      mode="sg-markets"
      producer-code={partialIncidentProducerCode}
      // @ts-expect-error
      ref={ref}
      theme-switcher="true"
    />
  );
};
