import { Button } from '@sgme/ui';
import { type FC, useCallback, useContext } from 'react';
import { FormattedText } from '@/components/shared/FormattedText';
import { SgwtWidgetsContext } from '@/contexts/SgwtWidgets/SgwtWidgetsContext';
import type { ButtonProps } from '@sgme/ui';
import type { IntlKey } from '@/locales';

export type ContactUsButtonProps = Omit<ButtonProps, 'onClick'> & {
  label?: IntlKey;
};

export const ContactUsButton: FC<ContactUsButtonProps> = ({ label = 'Contact us', purpose = 'primary', ...props }) => {
  const sgwtWidgets = useContext(SgwtWidgetsContext);
  const openContactUsForm = useCallback(() => {
    sgwtWidgets.helpCenter?.form();
  }, [sgwtWidgets]);
  return <FormattedText as={Button} id={label} purpose={purpose} onClick={openContactUsForm} {...props} />;
};
