import { ErrorState } from '@/components/shared/ErrorState';

export const NotFoundPage = () => (
  <main className="app-main container d-flex">
    <ErrorState
      color="secondary"
      icon="web_asset_off"
      messages={['The page that you are looking for cannot be found,', 'please check your URL and try again.']}
      showContactUsButton
      showGoBackButton
      title="Page not found"
    />
  </main>
);
