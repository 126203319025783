import { THEME_CHANGED, sgConnectTokenExpired } from './sessions.actions';

import { asyncScheduler, filter, ignoreElements, interval, map, tap } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';

import { applySgBootstrapTheme } from '@/theme';
import { localStorageApi } from '@/api/localStorage';
import { sgwtConnect } from '@/widgets/sgwtConnect';
import type { Epic } from 'redux-observable';
import type { LocalStorageApi } from '@/api/localStorage';
import type { SGWTConnectCore } from '@sgwt/connect-core';
import type { SchedulerLike } from 'rxjs';
import type { ThemeChanged } from './sessions.actions';

export const sessionsExpiredEpic =
  (sgConnect: SGWTConnectCore, scheduler: SchedulerLike): Epic =>
  () =>
    interval(1000, scheduler).pipe(
      filter(() => !sgConnect.isAuthorized()),
      map(() => sgConnectTokenExpired()),
    );

export const themeChangedEpic =
  (api: LocalStorageApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(THEME_CHANGED),
      tap(({ theme }: ThemeChanged) => {
        api.set('APP_THEME', theme);
        applySgBootstrapTheme(theme);
      }),
      ignoreElements(),
    );

export const sessionsEpics = () =>
  combineEpics(sessionsExpiredEpic(sgwtConnect, asyncScheduler), themeChangedEpic(localStorageApi));
