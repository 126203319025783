import classNames from 'classnames';
import type { FC } from 'react';

export const Td: FC<React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>> = ({
  children,
  className,
  ...others
}) => {
  return (
    <td {...others} className={classNames('', className)}>
      {children}
    </td>
  );
};
