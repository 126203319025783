import { type AuctionsState, initialAuctionsState } from '@/store/auctions';
import { type SessionsState, initialSessionsState } from '@/store/sessions/sessions.model';
import { initialUIState } from '@/store/ui';
import { initialUsersState } from '@/store/users/users.model';
import type { PreloadedState } from 'redux';
import type { UiState } from '@/store/ui';
import type { UsersState } from '@/store/users/users.model';

export interface RootState {
  auctions: AuctionsState;
  ui: UiState;
  sessions: SessionsState;
  users: UsersState;
}
export const initialState: PreloadedState<RootState> = {
  auctions: initialAuctionsState,
  ui: initialUIState,
  sessions: initialSessionsState,
  users: initialUsersState,
};
