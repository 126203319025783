import { FieldWrapper } from '@/components/shared/FieldWrapper';
import { SgDatePicker } from '@sgme/ui';
import { addDays, addMonths, addWeeks, addYears, startOfDay } from 'date-fns';
import React, { useMemo } from 'react';
import type { IntlKey } from '@/locales';
import type { PickerState } from '@sg-bootstrap/components';
import type { Size } from '@sgme/ui';

export interface DatePickerProps {
  className?: string;
  e2e?: string;
  format?: string;
  hideClearButton?: boolean;
  id?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  label: IntlKey;
  maxValue?: Date | string;
  minValue?: Date | string;
  neverEmpty?: boolean;
  onChange: (value?: string) => void;
  size?: Size;
  value?: Date | string;
  hidePresets?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  className,
  e2e,
  format = 'dd MMM yyyy',
  hideClearButton = false,
  id,
  isDisabled = false,
  isInvalid = false,
  label,
  maxValue,
  minValue,
  neverEmpty = false,
  onChange,
  size = 'md',
  value,
  hidePresets = false,
}) => {
  const today = new Date();
  const state = useMemo<PickerState>(() => (isInvalid ? 'invalid' : null), [isInvalid]);
  const dateValue = useMemo(() => (value ? startOfDay(new Date(value)) : undefined), [value]);

  const customPresets: Record<string, Date> = {
    Today: today,
    Tomorrow: addDays(today, 1),
    '1 Week': addWeeks(today, 1),
    '1 Month': addMonths(today, 1),
    '3 Months': addMonths(today, 3),
    '6 Months': addMonths(today, 6),
    '1 Year': addYears(today, 1),
  } satisfies Partial<Record<string, Date>>;

  return (
    <FieldWrapper className={className} label={label}>
      <SgDatePicker
        acceptEmptyValue={!neverEmpty}
        e2e={e2e}
        format={format}
        hidePresets={hidePresets}
        id={id}
        isDisabled={isDisabled}
        maxValue={maxValue}
        minValue={minValue}
        selectionType="single"
        onChange={onChange}
        showClearButton={!hideClearButton}
        size={size}
        validationState={state}
        datePresets={customPresets}
        values={dateValue ? [dateValue] : []}
      />
    </FieldWrapper>
  );
};
