import { addDays, parseISO, toDate } from 'date-fns';
import { format, fromZonedTime, getTimezoneOffset, toZonedTime } from 'date-fns-tz';

export function toStartOfDay(date: Date) {
  const startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0);
  return startDate;
}

export function toEndOfDay(date: Date) {
  const endDate = new Date(date);
  endDate.setHours(23, 59, 59, 999);
  return endDate;
}

export function toTimestamp(value: string): number {
  return parseISO(value).valueOf();
}

export function fromTimestampToStartOfDayString(value: number): string {
  const date = toDate(value);
  const startOfDay = toStartOfDay(date);

  return startOfDay.toISOString();
}

export function fromTimestampToEndOfDayString(value: number): string {
  const date = toDate(value);
  const endOfDay = toEndOfDay(date);

  return endOfDay.toISOString();
}

export function getTimeFromDate(isoDateString?: string): string | undefined {
  if (isoDateString === undefined) {
    return undefined;
  }

  const date = new Date(isoDateString);
  const hours = `0${date.getHours()}`.slice(-2);
  const minutes = `0${date.getMinutes()}`.slice(-2);
  return `${hours}:${minutes}`;
}

export function applyTimeToDate(time?: string, isoDateString?: string): string | undefined {
  if (time === undefined || isoDateString === undefined) {
    return undefined;
  }

  const date = new Date(parseISO(isoDateString).valueOf());

  const [hours, minutes] = time.split(':').map(Number);
  if (hours === undefined || minutes === undefined) {
    return undefined;
  }
  date.setHours(hours);
  date.setMinutes(minutes);
  return date.toISOString();
}

export function addDaysToIsoDateString(isoDateString?: string, days?: number) {
  if (isoDateString === undefined || days === undefined) {
    return undefined;
  }

  const date = new Date(parseISO(isoDateString).valueOf());
  return addDays(date, -1).toISOString();
}

export function getTimezoneOffsetFromTimeZoneId(timeZoneId: string): number {
  return getTimezoneOffset(timeZoneId);
}

export function getTimeZoneAbbreviation(date: string | number | Date, timeZoneId: string): string {
  const zonedDate = toZonedTime(date, timeZoneId);
  const abbreviation = format(zonedDate, 'zzz', { timeZone: timeZoneId });
  return abbreviation;
}

export function formatTimeWithTimeZoneAbbreviation(time: string, timeZoneId: string): string {
  return `${getTimeFromDate(time)} ${getTimeZoneAbbreviation(time, timeZoneId)}`;
}

export function formatTimeWithoutTimeZoneAbbreviation(time: string): string {
  return `${getTimeFromDate(time)}`;
}

export function applyTimeAndConvertToUTC(originalValue: Date, time: string, timezoneId: string) {
  const zonedTime = applyTimeToDate(time, originalValue.toISOString());
  if (zonedTime !== undefined) {
    return fromZonedTime(zonedTime, timezoneId).toISOString();
  }
  return originalValue.toISOString();
}
