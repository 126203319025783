import { EMPTY, Subject, catchError, of, switchMap, tap } from 'rxjs';
import { referentialApiConnector } from '@/api';
import { useEffect, useRef, useState } from 'react';
import type { AsyncResponse, StrategyTypeEnum } from '@/models';
import type { Underlying } from '@/api';

const referentialApi$ = referentialApiConnector();

interface UseUnderlyingProps {
  strategyType: StrategyTypeEnum;
}

export const useUnderlying = ({ strategyType }: UseUnderlyingProps) => {
  const inputRef = useRef<Subject<string> | undefined>();
  const [response, setResponse] = useState<AsyncResponse<{ underlyings: Underlying[] }>>({ isLoading: false });

  useEffect(() => {
    // initialize inputRef.current here is to avoid Effect firing twice in development StrictMode
    inputRef.current = new Subject();
    inputRef.current
      .pipe(
        tap(() => setResponse({ isLoading: true })),
        switchMap(input =>
          input.length > 1
            ? referentialApi$
                .getUnderlyings({ query: { strategyType, bloombergCodePrefix: input } })
                .pipe(catchError(() => EMPTY))
            : of({ entries: [] }),
        ),
      )
      .subscribe(({ entries }) => setResponse({ isLoading: false, underlyings: entries }));
    return () => {
      if (inputRef.current) {
        inputRef.current.unsubscribe();
      }
    };
  }, [strategyType]);

  return { search: (input: string) => inputRef.current && inputRef.current.next(input), response };
};
