import { FormattedMessage } from 'react-intl';
import { isNonEmpty } from '@sgme/fp';
import classNames from 'classnames';
import type { Color, Purpose } from '@/models';
import type { FC, PropsWithChildren } from 'react';

export interface AlertBannerProps {
  purpose?: Purpose;
  color?: Color;
  dismissible?: boolean;
  iconName?: string;
  title?: string;
  className?: string;
}

export const AlertBanner: FC<PropsWithChildren<AlertBannerProps>> = ({
  purpose = undefined,
  color = 'primary',
  dismissible,
  iconName,
  title,
  className,
  children,
}) => {
  const alertStyle = getAlertStyle(purpose, color);

  return (
    <div className={classNames('alert', alertStyle, { 'alert-dismissible': dismissible }, className)} role="alert">
      <div className="d-flex align-items-center">
        {isNonEmpty(iconName) && <i className="icon icon-md me-2 lh-1">{iconName}</i>}
        {isNonEmpty(title) && <FormattedMessage id={`alert.${title}`} defaultMessage={title} />}
        {children}
      </div>
    </div>
  );
};

function getAlertStyle(purpose?: Purpose, color?: Color): string {
  const effectivePurpose = purpose ?? '';
  const effectiveColor = color;

  if (effectivePurpose === 'primary' && effectiveColor === 'primary') {
    return 'alert-primary';
  }

  return `alert-${color}${isNonEmpty(effectivePurpose) ? `-${effectivePurpose}` : ''}`;
}
