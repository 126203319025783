import {
  CURRENT_USER_INFO_FETCHED,
  CURRENT_USER_INFO_FETCH_FAILED,
  CURRENT_USER_INFO_REQUESTED,
} from './users.actions';
import { initialUsersState } from './users.model';
import type { Actions } from '@/store/app.actions';
import type { UsersState } from './users.model';

export const usersReducer = (state: UsersState = initialUsersState, action: Actions): UsersState => {
  switch (action.type) {
    case CURRENT_USER_INFO_REQUESTED:
      return { isLoading: true };
    case CURRENT_USER_INFO_FETCHED:
      return { isLoading: false, user: action.user };
    case CURRENT_USER_INFO_FETCH_FAILED:
      return { isLoading: false, error: action.error };
    default:
      return state;
  }
};
