import { FormattedText } from '@/components/shared/FormattedText';
import { TextInput } from '@/components/shared/TextInput';
import { isDefined } from '@sgme/fp';
import classNames from 'classnames';
import type { IntlKey } from '@/locales';

type DataRowProps = {
  id: string;
  label: string;
  translationKey?: IntlKey;
  value?: string;
  isOnEditMode?: boolean;
  isReadOnly?: boolean;
  onBlurInput?: (value?: string) => void;
};

export function DataRow({
  id,
  label,
  translationKey,
  value,
  isOnEditMode = false,
  isReadOnly = false,
  onBlurInput,
}: DataRowProps) {
  return (
    <div
      className={classNames(
        'border-bottom d-flex justify-content-between align-items-center',
        isOnEditMode ? 'py-1' : 'py-2',
      )}
    >
      <span className="text-secondary" data-e2e={`${id}-data-row-label`}>
        {label}
      </span>
      {isReadOnly || !isOnEditMode ? (
        <>
          {isDefined(translationKey) ? (
            <FormattedText as="span" id={translationKey} />
          ) : (
            <FormattedText as="span" text={value} />
          )}
        </>
      ) : (
        <TextInput value={value} onBlur={onBlurInput} e2e={id} placeholder="" textAlign="end" />
      )}
    </div>
  );
}
