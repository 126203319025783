import { FoldableCard } from '@/components/shared/FoldableCard/FoldableCard';
import Skeleton from 'react-loading-skeleton';
import type { FC } from 'react';

export interface FoldableCardLoadingSkeletonProps {
  className?: string;
  count?: number;
}
export const FoldableCardLoadingSkeleton: FC<FoldableCardLoadingSkeletonProps> = ({ className, count = 1 }) => {
  return (
    <>
      {[...Array(count)].map((_, i) => (
        <FoldableCard key={i} className={className}>
          <div data-e2e="loading-skeleton" className="card-header d-flex">
            <Skeleton containerClassName="flex-fill" height="2em" />
          </div>
        </FoldableCard>
      ))}
    </>
  );
};
