import { keyBy } from 'lodash';
import { toast } from '@/components/shared/Toast';
import type { BlotterItem } from '@/models';

export function mergeWithLatestBlotterItems(currentBlotterItems: BlotterItem[], incomingBlotterItems: BlotterItem[]) {
  const mergedItems: BlotterItem[] = [];
  const allItems: BlotterItem[] = [];

  const incomingItemsMap = keyBy(incomingBlotterItems, 'auction.auctionUuid');

  currentBlotterItems.forEach(currentItem => {
    const auctionId = currentItem.auction.auctionUuid;
    const incomingItem = incomingItemsMap[auctionId];

    if (incomingItem && incomingItem.lastUpdate > currentItem.lastUpdate) {
      mergedItems.push(incomingItem);
      allItems.push(incomingItem);
    } else {
      allItems.push(currentItem);
    }
  });

  return { mergedItems, allItems };
}

export function triggerToast(blotterItems: BlotterItem[]) {
  blotterItems.forEach(item => {
    toast.info({
      header: {
        id: 'Auction {strategy} has been updated',
        values: { strategy: item.auction.strategy ?? '' },
      },
      message: {
        id: 'We have received some updates for the auction {strategy} and refreshed the content in your screen.',
        values: { strategy: item.auction.strategy ?? '' },
      },
    });
  });
}
