import {
  createAuctionRequested,
  derogateReceived,
  editAuctionRequested,
  enrichStrategyFormReceived,
  enrichStrategyFormRequested,
  latestBlotterItemsReceived,
  pdcDetailsRequested,
} from '@/store/auctions/auctions.actions';
import { getStrategyFormStaticValues } from '@/store/auctions/auctions.selectors';
import { mergeWithLatestBlotterItems, triggerToast } from '@/store/auctions/auctions.helpers';
import type { BlotterItem, StrategyFormResult, VariationParameters } from '@/models';
import type { Thunk } from '@/store/app.actions';

export const enrichStrategyFormThunk =
  (vp: VariationParameters): Thunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    const staticValues = getStrategyFormStaticValues(state);
    dispatch(enrichStrategyFormRequested({ ...staticValues, ...vp }));
  };

export const updateBlotterItemsThunk =
  (incomingBlotterItems: BlotterItem[]): Thunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    const currentBlotterItems = state.auctions.current.blotterItems;

    if (currentBlotterItems === undefined) {
      return;
    }

    const { mergedItems, allItems } = mergeWithLatestBlotterItems(currentBlotterItems, incomingBlotterItems);

    triggerToast(mergedItems);
    dispatch(latestBlotterItemsReceived(allItems));
  };

export const enrichStrategyFormReceivedThunk =
  (strategyFormResult: StrategyFormResult): Thunk<void> =>
  (dispatch, getState) => {
    dispatch(enrichStrategyFormReceived(strategyFormResult));
    const state = getState();
    if (state.auctions.create.saveAfterEnrich) {
      dispatch(createAuctionRequested());
    }
    if (state.auctions.edit.saveAfterEnrich && state.auctions.edit.auctionUuid) {
      dispatch(editAuctionRequested(state.auctions.edit.auctionUuid));
    }
  };

export const derogateReceivedThunk =
  (rfqId: string | undefined): Thunk<void> =>
  dispatch => {
    dispatch(derogateReceived());
    if (rfqId !== undefined) {
      dispatch(pdcDetailsRequested([rfqId]));
    }
  };
