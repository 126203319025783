import { DataRow } from '@/components/shared/DataRow';
import { type FC, useCallback, useMemo } from 'react';
import { Modal, type ModalProps } from '@/components/shared/Modal';
import {
  executeAuctionsBookingDetailsPatchRequested,
  executeAuctionsConfirmationStepChanged,
  executeAuctionsRequested,
} from '@/store/auctions';
import { formatCcyValue, formatNumberWithDecimals } from '@/utils/string';
import { formatTimeWithTimeZoneAbbreviation } from '@/utils/date';
import { getRfqDealerFromAuctionsBlotter, isExecuting } from '@/store/auctions/auctions.selectors';
import { isDefined, isNonEmpty, isNotDefined } from '@sgme/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import type { Auction } from '@/models';
import type { AuctionExecutionConfirmationState } from '@/models/AuctionExecutionState';
import type { IntlKey } from '@/locales';

export interface AuctionExecutionModalProps extends ModalProps {
  auction?: Auction;
  auctionExecutionConfirmation?: AuctionExecutionConfirmationState;
}

const AuctionExecutionModal: FC<AuctionExecutionModalProps> = ({
  initialDisplayed,
  onClose,
  auction,
  auctionExecutionConfirmation,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const auctionId = auctionExecutionConfirmation?.auctionId;
  const dealerId = auctionExecutionConfirmation?.dealerId;
  const bookingDetails = auctionExecutionConfirmation?.bookingDetails;
  const bookingPortfolio = bookingDetails?.bookingPortfolio;
  const bookingCenter = bookingDetails?.bookingCenter;
  const markitWireId = bookingDetails?.markitWireId;

  const dealer = useSelector(getRfqDealerFromAuctionsBlotter(auctionId, dealerId));
  const isExecutingAuction = useSelector(isExecuting);
  const firstAuctionInstrument = auction?.auctionBaseRfq?.instruments?.find(isDefined);
  const timeZoneId = auction?.auctionBaseRfq?.salesDiffusion?.salesValo?.timeZoneId;
  const masterLeg = firstAuctionInstrument?.instrumentLegs?.find(leg => leg.master);

  const instrumentValue = `${firstAuctionInstrument ? formatMessage({ id: `instrumentType.${firstAuctionInstrument?.instrumentType}` }) : '-'} ${masterLeg?.underlying?.bloombergCode ?? '-'}`;

  const isInConfirmationStep = useMemo(
    () => auctionExecutionConfirmation?.step === 'CONFIRMATION',
    [auctionExecutionConfirmation?.step],
  );

  const isReadyToSubmit = useMemo(
    () => isNonEmpty(bookingCenter) && isNonEmpty(bookingPortfolio) && isNonEmpty(markitWireId),
    [bookingCenter, bookingPortfolio, markitWireId],
  );

  const patchBookingPortfolio = useCallback(
    (value?: string) => {
      dispatch(executeAuctionsBookingDetailsPatchRequested({ bookingPortfolio: value }));
    },
    [dispatch],
  );
  const patchBookingCenter = useCallback(
    (value?: string) => {
      dispatch(executeAuctionsBookingDetailsPatchRequested({ bookingCenter: value }));
    },
    [dispatch],
  );
  const patchMarkitWireId = useCallback(
    (value?: string) => {
      dispatch(executeAuctionsBookingDetailsPatchRequested({ markitWireId: value }));
    },
    [dispatch],
  );

  const { cancelLabelKey, confirmLabelKey }: { cancelLabelKey?: IntlKey; confirmLabelKey?: IntlKey } = useMemo(() => {
    const step = auctionExecutionConfirmation?.step;
    if (!isDefined(step)) {
      return {
        cancelLabelKey: undefined,
        confirmLabelKey: undefined,
      };
    }

    if (isInConfirmationStep) {
      return {
        cancelLabelKey: 'Back',
        confirmLabelKey: 'Confirm execution',
      };
    }
    return {
      cancelLabelKey: 'Cancel',
      confirmLabelKey: 'Next',
    };
  }, [auctionExecutionConfirmation?.step, isInConfirmationStep]);

  const cancelButtonClicked = useCallback(() => {
    const step = auctionExecutionConfirmation?.step;
    if (isNotDefined(step) || isNotDefined(onClose)) {
      return;
    }

    if (isInConfirmationStep) {
      dispatch(executeAuctionsConfirmationStepChanged('EDITION'));
      return;
    }
    onClose();
  }, [auctionExecutionConfirmation?.step, dispatch, isInConfirmationStep, onClose]);

  const confirmButtonClicked = useCallback(() => {
    const step = auctionExecutionConfirmation?.step;
    if (isNotDefined(step) || isNotDefined(auctionId) || isNotDefined(dealerId)) {
      return;
    }

    if (isInConfirmationStep) {
      dispatch(executeAuctionsRequested(auctionId, dealerId));
      return;
    }
    dispatch(executeAuctionsConfirmationStepChanged('CONFIRMATION'));
  }, [auctionExecutionConfirmation?.step, auctionId, dealerId, dispatch, isInConfirmationStep]);

  const dealerPrice = dealer?.price;
  return (
    <Modal
      title="Execution summary"
      initialDisplayed={initialDisplayed}
      onClose={onClose}
      onCancel={cancelButtonClicked}
      onConfirm={confirmButtonClicked}
      cancelActionLabelKey={cancelLabelKey}
      confirmActionLabelKey={confirmLabelKey}
      confirmActionDisabled={!isReadyToSubmit}
      bordered
      color="primary"
      loading={isExecutingAuction}
    >
      <p className="mb-3">You are about to execute:</p>
      <div className="mt-2">
        <span className="fw-bold">Quote details</span>
        <div className="mt-2">
          <DataRow id="Dealer" label="Dealer" value={dealer?.salesCounterparty?.name} />
          <DataRow id="Instrument" label="Instrument" value={instrumentValue} />
          <DataRow
            id="LotSize"
            label="Lot size"
            value={
              isDefined(masterLeg?.negotiatedSize?.lotSize)
                ? formatNumberWithDecimals(masterLeg.negotiatedSize.lotSize)
                : '-'
            }
          />
          <DataRow
            id="Size"
            label="Size"
            value={
              isDefined(masterLeg?.negotiatedSize?.numberOfLots)
                ? formatNumberWithDecimals(masterLeg.negotiatedSize.numberOfLots)
                : '-'
            }
          />
          <DataRow
            id="Quantity"
            label="Quantity"
            value={
              isDefined(masterLeg?.negotiatedSize?.quantity)
                ? formatNumberWithDecimals(masterLeg.negotiatedSize.quantity)
                : '-'
            }
          />
          <DataRow
            id="Price"
            label="Price"
            value={isDefined(dealerPrice?.value) ? formatCcyValue(dealerPrice.value, dealerPrice.unit) : '-'}
          />
          <DataRow
            id="Time"
            label="Time"
            value={
              isDefined(dealer?.receiveTime) && isNonEmpty(timeZoneId)
                ? formatTimeWithTimeZoneAbbreviation(dealer.receiveTime, timeZoneId)
                : '-'
            }
          />
        </div>
      </div>

      <div className="mt-4">
        <span className="fw-bold">Booking details</span>
        <div className="mt-2">
          <DataRow
            id="BookingPortfolio"
            label="Booking portfolio"
            isOnEditMode={true}
            isReadOnly={isInConfirmationStep}
            value={bookingPortfolio}
            onBlurInput={patchBookingPortfolio}
          />
          <DataRow
            id="BookingCenter"
            label="Booking center"
            isOnEditMode={true}
            isReadOnly={isInConfirmationStep}
            value={bookingCenter}
            onBlurInput={patchBookingCenter}
          />
          <DataRow
            id="MarkitWireId"
            label="MarkitWire ID"
            isOnEditMode={true}
            isReadOnly={isInConfirmationStep}
            value={markitWireId}
            onBlurInput={patchMarkitWireId}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AuctionExecutionModal;
