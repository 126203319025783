import { dealersApiConnector } from '@/api/dealers';
import { firstValueFrom } from 'rxjs';
import { useEffect, useState } from 'react';
import type { DealersApi } from '@/api/dealers';
import type { Sales } from '@/models';

const dealersApi$: DealersApi = dealersApiConnector();
export const useFetchSales = () => {
  const [availableSales, setAvailableSales] = useState<Sales[]>([]);

  useEffect(() => {
    firstValueFrom(dealersApi$.getSales()).then(({ entries }) => {
      setAvailableSales(entries);
    });
  }, []);

  return availableSales;
};
