import { httpClient } from '@/utils/http';
import { map } from 'rxjs';
import queryString from 'query-string';
import type {
  BlotterItem,
  BlotterItemListResponse,
  ConfirmDealersRequest,
  ConfirmDealersResponse,
  CreateAuctionRequest,
  CreateAuctionResponse,
  EnrichRequest,
  ExecuteAuctionRequest,
  ExecuteAuctionResponse,
  Path,
  PdcDerogation,
  StrategyFormResult,
  UpdateAuctionRequest,
  paths,
} from '@/models';
import type { PdcDetailsResponse } from '@/helper/pdc';

export type AuctionsApi = ReturnType<typeof auctionsApiConnector>;
export const auctionsApiConnector = () => ({
  getAuctions(params: paths['/api/v1/auctions']['get']['parameters']) {
    const { query } = params;
    const queryParams = query ? `${queryString.stringify(query)}` : '';

    return httpClient
      .getJSON<{ entries: BlotterItem[] }>({ url: `/api/v1/auctions?${queryParams}` })
      .pipe(map(({ payload }) => payload));
  },
  postAuction(request: CreateAuctionRequest) {
    return httpClient
      .post<CreateAuctionResponse>({
        url: '/api/v1/auctions',
        body: request,
      })
      .pipe(map(({ payload }) => payload));
  },
  putAuction(auctionUuid: string, request: UpdateAuctionRequest) {
    return httpClient
      .put<StrategyFormResult>({
        url: `/api/v1/auctions/${auctionUuid}` as Path,
        body: request,
      })
      .pipe(map(({ payload }) => payload));
  },
  executeAuction(auctionUuid: string, request: ExecuteAuctionRequest) {
    return httpClient
      .put<ExecuteAuctionResponse>({
        url: `/api/v1/auctions/${auctionUuid}/winner`,
        body: request,
      })
      .pipe(map(({ payload }) => payload));
  },
  initForm(params: paths['/api/v1/auctions/{rfqId}/init-form']['get']['parameters']) {
    const { path, query } = params;
    const queryParams = query ? `${queryString.stringify(query)}` : '';

    return httpClient
      .getJSON<StrategyFormResult>({ url: `/api/v1/auctions/${path.rfqId}/init-form?${queryParams}` as Path })
      .pipe(map(({ payload }) => payload));
  },
  enrichForm(request: EnrichRequest, rfqId?: string) {
    return httpClient
      .post<StrategyFormResult>({
        url: `/api/v1/auctions/${rfqId}/enrich-form`,
        body: request,
      })
      .pipe(map(({ payload }) => payload));
  },
  reloadForm(rfqId: string) {
    return httpClient
      .getJSON<StrategyFormResult>({ url: `/api/v1/auctions/${rfqId}` as Path })
      .pipe(map(({ payload }) => payload));
  },
  confirmDealers(auctionUuid: string, request: ConfirmDealersRequest) {
    return httpClient
      .put<ConfirmDealersResponse>({
        url: `/api/v1/auctions/${auctionUuid}/confirm-dealers`,
        body: request,
      })
      .pipe(map(({ payload }) => payload));
  },
  getLatestBlotterItem(auctionIds: string[]) {
    const body = { auctionIds };
    return httpClient
      .post<BlotterItemListResponse>({ url: '/api/v1/auctions/latest' as Path, body })
      .pipe(map(({ payload }) => payload.entries ?? []));
  },
  overrideQuote(
    dealerRfqId: string | undefined,
    priceValue: string | null | undefined,
    priceUnit: string | null | undefined,
    priceType: string | null | undefined,
    receiveTime: string,
  ) {
    const body = {
      dealerRfqId: dealerRfqId,
      price: {
        value: priceValue,
        unit: priceUnit,
        type: priceType,
      },
      receiveTime: receiveTime,
    };
    return httpClient
      .put<boolean>({ url: '/api/v1/auction/override' as Path, body })
      .pipe(map(({ payload }) => payload));
  },
  cancelAuction(auctionUuid: string | undefined) {
    const body = {
      auctionUuid: auctionUuid,
    };
    return httpClient.put<boolean>({ url: '/api/v1/auction/cancel' as Path, body }).pipe(map(({ payload }) => payload));
  },
  getPdcDetails(rfqIds: string[] | undefined) {
    return httpClient
      .post<PdcDetailsResponse>({ url: '/api/v1/pdc/bulk-get-details', body: { rfqIds } })
      .pipe(map(({ payload }) => payload));
  },
  getPdcDerogationReasons() {
    return httpClient
      .getJSON<PdcDerogation>({ url: '/api/v1/pdc/derogation-reasons' })
      .pipe(map(({ payload }) => payload));
  },
  derogate(derogationReason: string | undefined, pdcUuid: string | undefined, rfqId: string | undefined) {
    const body = { pdcDerogation: derogationReason, pdcUuid: pdcUuid, rfqUuid: rfqId };
    return httpClient.post({ url: '/api/v1/pdc/derogate', body }).pipe(map(({ payload }) => payload));
  },
});
