import { type FC, useCallback, useMemo } from 'react';
import { FieldWrapper } from '@/components/shared/FieldWrapper';
import { SgDaterangepicker } from '@sg-bootstrap/components/dist/react-library/src/components';
import { fromTimestampToEndOfDayString, fromTimestampToStartOfDayString, toTimestamp } from '@/utils/date';
import { startOfDay } from 'date-fns';
import type { DateRange } from '@/models/dateRange';
import type { IEmittedDates, SgDaterangepickerCustomEvent } from '@sg-bootstrap/components';
import type { IntlKey } from '@/locales';

type IsoString = string;

export interface DateRangePickerProps {
  label: IntlKey;
  dateRange: DateRange;
  className?: string;
  onChange?: (dateRange: DateRange) => void;
  maxValue?: Date | IsoString;
  minValue?: Date | IsoString;
  hideClearButton?: boolean;
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
  label,
  className,
  dateRange,
  onChange,
  maxValue,
  minValue,
  hideClearButton = false,
}) => {
  const disablePresets = useMemo(() => {
    const rules: string[] = [];
    if (minValue) {
      rules.push(`before:${startOfDay(minValue).getTime()}`);
    }
    if (maxValue) {
      rules.push(`after:${startOfDay(maxValue).getTime()}`);
    }
    return rules.join(', ');
  }, [minValue, maxValue]);

  const onDateChanged = useCallback(
    ({ target }: SgDaterangepickerCustomEvent<IEmittedDates>) => {
      if (target.startDate === undefined || target.endDate === undefined || onChange === undefined) {
        return;
      }

      const data: DateRange = {
        startDate: fromTimestampToStartOfDayString(target.startDate),
        endDate: fromTimestampToEndOfDayString(target.endDate),
      };
      if (data.startDate !== dateRange?.startDate || data.endDate !== dateRange?.endDate) {
        onChange(data);
      }
    },
    [dateRange, onChange],
  );
  return (
    <>
      <FieldWrapper label={label} className={className}>
        <SgDaterangepicker
          className={className}
          startDate={toTimestamp(dateRange.startDate)}
          endDate={toTimestamp(dateRange.endDate)}
          onDateChanged={onDateChanged}
          never-empty={true}
          disablePresets={disablePresets}
          noClearButton={hideClearButton}
        />
      </FieldWrapper>
    </>
  );
};
