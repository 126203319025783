import type {
  AsyncResponse,
  BlotterItem,
  CreateAuctionResponse,
  PdcDerogation,
  PdcDetails,
  StrategyFormResult,
} from '@/models';
import type { AuctionExecutionConfirmationState, AuctionExecutionState } from '@/models/AuctionExecutionState';

export interface AuctionsState {
  current: AsyncResponse<{
    blotterItems: BlotterItem[];
    pdcDetails: PdcDetails[];
    pdcDerogation: PdcDerogation | undefined;
  }>;
  history: AsyncResponse<{ history: [] }>;
  enrich: AsyncResponse<{
    isInit: boolean;
    strategyFormResult: StrategyFormResult;
    pendingEnrichment: StrategyFormResult;
  }>;
  create: AsyncResponse<{ response: CreateAuctionResponse; saveAfterEnrich: boolean }>;
  edit: AsyncResponse<{ response: StrategyFormResult; saveAfterEnrich: boolean; auctionUuid?: string }>;
  execute: AsyncResponse<{
    auctions: Record<string, AuctionExecutionState>;
    confirmation?: AuctionExecutionConfirmationState;
  }>;
}

export const initialAuctionsState: AuctionsState = {
  current: { isLoading: false },
  history: { isLoading: false },
  enrich: { isLoading: false, isInit: false },
  create: { isLoading: false, saveAfterEnrich: false },
  edit: { isLoading: false, saveAfterEnrich: false },
  execute: { isLoading: false },
};

export type AuctionKind = keyof AuctionsState;
