import { Button } from '@sgme/ui';
import { type FC, type PropsWithChildren } from 'react';
import { useFoldableCardContext } from '@/components/shared/FoldableCard/FoldableCardContext';
import classNames from 'classnames';

export const FoldableCardHeader: FC<PropsWithChildren> = ({ children }) => {
  const { open, toggle } = useFoldableCardContext();

  return (
    <div className="card-header d-flex align-items-center p-4">
      <Button
        purpose="link"
        iconPosition="icon-only"
        className={classNames('btn-collapse me-3', { collapsed: !open })}
        type="button"
        aria-expanded={open}
        onClick={toggle}
      />
      {children}
    </div>
  );
};
