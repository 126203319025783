import type { FC, PropsWithChildren } from 'react';

export interface NavProps {
  className?: string;
}

export const AppNav: FC<PropsWithChildren<NavProps>> = ({ children, className }) => {
  return (
    <div className={className}>
      <nav className="border-bottom w-100">
        <div className="container d-flex flex-column justify-content-between">{children}</div>
      </nav>
    </div>
  );
};
