import { type ComponentPropsWithoutRef, type ElementType, Fragment } from 'react';
import { FormattedPlural as ReactIntlFormattedPlural } from 'react-intl';
import type { IntlKey } from '@/locales';

type FormattedPluralProps<T extends ElementType> = {
  as?: T;
  value: number;
  one: IntlKey;
  other: IntlKey;
} & ComponentPropsWithoutRef<T>;

export function FormattedPlural<T extends ElementType>({ as, value, one, other, ...props }: FormattedPluralProps<T>) {
  const Component = as ?? Fragment;
  return (
    <Component {...props}>
      <ReactIntlFormattedPlural value={value} one={one} other={other} />
    </Component>
  );
}
