import { createContext, useContext } from 'react';

interface IFoldableCardContext {
  open: boolean;
  toggle: () => void;
}
export const FoldableCardContext = createContext<IFoldableCardContext | undefined>(undefined);

export const useFoldableCardContext = () => {
  const context = useContext(FoldableCardContext);

  if (!context) {
    throw new Error('useThemeContext must be used inside the ThemeProvider');
  }

  return context;
};
