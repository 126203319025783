import { AppFooter } from '@/components/AppFooter';
import { AppHeader } from '@/components/AppHeader';
import { Outlet } from 'react-router-dom';
import { ToastsContainer } from '@/components/shared/Toast/ToastsContainer';
import { usePageTitle } from '@/utils/pageTitle';

export const RootOutlet = () => {
  usePageTitle();

  return (
    <div className="app d-flex flex-column">
      <AppHeader />
      <ToastsContainer />
      <div className="flex-fill overflow-hidden">
        <Outlet />
      </div>
      <div className="d-flex justify-content-end"></div>
      <AppFooter />
    </div>
  );
};
