import { createContext, useContext } from 'react';

interface IModalContext {
  displayed: boolean;
  open: () => void;
  close: () => void;
}
export const ModalContext = createContext<IModalContext | undefined>(undefined);

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useThemeContext must be used inside the ThemeProvider');
  }

  return context;
};
