import { AppMain } from '@/components/AppMain';
import { AppNav, NavActions, NavHeader, NavTitle } from '@/components/AppNav';
import { Button } from '@sgme/ui';
import { EnrichForm } from '@/components/EnrichForm';
import { FormattedText } from '@/components/shared/FormattedText';
import { GoCurrentPageButton } from '@/components/shared/GoBackButton';
import {
  canCancelAuctionDetails,
  editAuctionQueued,
  editAuctionRequested,
  getAuctionsStatus,
  getBlotterAuctionByUuid,
  getIsButtonDisabled,
  reloadAuctionRequested,
} from '@/store/auctions';
import { getCancelModalIsOpened, openCancelModal } from '@/store/ui';
import { hasPermission } from '@/store/users';
import { useCallback, useEffect, useMemo } from 'react';
import { useCopyTextToClipboard } from '@/hooks/useCopyToClipboard/useCopyToClipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useRedirect } from '@/hooks/useRedirect';
import CancelModal from '@/components/Modal/CancelModal';
import type { Auction } from '@/models';

export const EditPage = () => {
  const { auctionUuid } = useParams<{ auctionUuid: string }>();
  const dispatch = useDispatch();
  const { isLoading: isEditLoading, response } = useSelector(getAuctionsStatus('edit'));
  const { isLoading: isEnrichLoading, isInit: isInitializing } = useSelector(getAuctionsStatus('enrich'));
  const isUpdateButtonDisabled = useSelector(getIsButtonDisabled('UpdateAuction'));
  const hasViewTechnicalIdPermission = useSelector(hasPermission('VIEW_TECHNICAL_ID'));

  const auction = useSelector(getBlotterAuctionByUuid(auctionUuid));

  useRedirect({
    to: '/auctions/current',
    when: response !== undefined || auction?.auction?.workflowStatus === 'AUCTION_CANCELLED',
  });

  useEffect(() => {
    dispatch(reloadAuctionRequested(auctionUuid ?? ''));
  }, [dispatch, auctionUuid]);

  const onCancelButtonClicked = useCallback(() => {
    if (auctionUuid !== undefined) {
      dispatch(openCancelModal(auctionUuid, auction?.auction.strategy));
    }
  }, [auctionUuid, dispatch, auction]);

  const showCancelAuctionButton = useSelector(canCancelAuctionDetails(auction?.auction as Auction));

  const cancelModalIsOpened = useSelector(getCancelModalIsOpened());

  const canCopyAuctionId = useMemo(() => hasViewTechnicalIdPermission, [hasViewTechnicalIdPermission]);

  const extractAuctionId = useCallback(() => auctionUuid ?? '', [auctionUuid]);
  const onCopyAuctionIdClicked = useCopyTextToClipboard(extractAuctionId, undefined, undefined, false);

  return (
    <>
      <AppNav>
        <NavHeader showBackButton>
          <FormattedText id="Edit auction details" as={NavTitle} />
          <NavActions>
            {canCopyAuctionId && (
              <FormattedText
                as={Button}
                id={'Copy auction ID'}
                purpose="link"
                onClick={onCopyAuctionIdClicked}
                size="lg"
              />
            )}
            <GoCurrentPageButton size="lg" label="Discard" />
            {showCancelAuctionButton && (
              <FormattedText
                as={Button}
                id={'Cancel auction'}
                onClick={onCancelButtonClicked}
                className="ms-2"
                size="lg"
              />
            )}
            <FormattedText
              id={isEnrichLoading ? 'Validating your inputs' : 'Save details'}
              as={Button}
              className="ms-2"
              disabled={isEditLoading || isInitializing || isEnrichLoading || isUpdateButtonDisabled}
              loading={isEditLoading || isEnrichLoading || isInitializing}
              loadingSpinnerPosition="end"
              purpose="primary"
              color="primary"
              size="lg"
              onClick={() => auctionUuid && dispatch(editAuctionRequested(auctionUuid))}
              onMouseDown={() => dispatch(editAuctionQueued(auctionUuid))}
            />
          </NavActions>
        </NavHeader>
      </AppNav>
      <AppMain>
        <EnrichForm page="edit" readonly={isEditLoading || isEnrichLoading} />
      </AppMain>
      {cancelModalIsOpened && <CancelModal initialDisplayed={true} />}
    </>
  );
};
