import classNames from 'classnames';
import type { FC, PropsWithChildren } from 'react';

export interface CardProps {
  className?: string;
  padding?: 3 | 4;
}

export const Card: FC<PropsWithChildren<CardProps>> = ({ className, children, padding = 4 }) => {
  return <div className={classNames('card', `p-${padding}`, className)}>{children}</div>;
};
