export function formatNumberWithDecimals(value: number, decimals?: number) {
  return value.toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

export function formatCcyValue(value: number, unit = '') {
  const valueWithFractionDigits = value.toLocaleString('en-US', {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });

  return `${valueWithFractionDigits}${formatUnit(unit)}`;
}

function formatUnit(unit: string = '') {
  return unit === '%' ? unit : ' '.concat(unit);
}
