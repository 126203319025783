import { Fragment, useMemo } from 'react';
import { formatCcyValue } from '@/utils/string';
import type { ComponentPropsWithRef, ElementType } from 'react';
import type { ValueUnitType } from '@/models';

type FormattedNumberProps<T extends ElementType> = {
  as?: T;
  strikes?: (ValueUnitType | undefined)[];
} & ComponentPropsWithRef<T>;

export function FormattedStrikes<T extends ElementType>({ as, strikes = [], ...props }: FormattedNumberProps<T>) {
  const Component = as ?? Fragment;

  const concatenatedStrikes = useMemo(() => concatStrikes(strikes), [strikes]);

  return <Component {...props}>{concatenatedStrikes}</Component>;
}

function concatStrikes(strikes: ValueUnitType[]) {
  return strikes
    ?.map(strike => (strike?.value === undefined ? '-' : formatCcyValue(strike?.value, strike?.unit)))
    .join('; ');
}
