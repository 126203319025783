import { FormattedMessage } from 'react-intl';
import type { FC } from 'react';
import type { WorkflowStatus } from '@/models';

export interface WorkflowStatusProps {
  status: WorkflowStatus;
}

export const WorkflowStatusBadge: FC<WorkflowStatusProps> = ({ status }) => {
  const [color, icon] = getWorkflowStatusStyle(status);

  return (
    <span className={`d-inline-flex align-items-center badge badge-discreet-${color} rounded-pill`}>
      {icon && <em className="icon icon-outlined icon-xs me-1">{icon}</em>}
      <FormattedMessage id={`workflowStatus.${status}`} />
    </span>
  );
};

function getWorkflowStatusStyle(status: WorkflowStatus) {
  switch (status) {
    case 'NEW':
      return ['primary', 'pending'];
    case 'AUCTION_CREATED':
      return ['primary', 'pending'];
    case 'AUCTION_PDC_T_MINUS_1_IN_PROGRESS':
      return ['info', 'schedule'];
    case 'AUCTION_PDC_T_MINUS_1_COMPLETED':
      return ['success', 'info'];
    case 'AUCTION_PDC_IN_PROGRESS':
      return ['info', 'schedule'];
    case 'AUCTION_PDC_COMPLETED':
      return ['warning', 'error_outline'];
    case 'AUCTION_AWAITING_TO_START':
      return ['info', 'schedule'];
    case 'AUCTION_ONGOING':
      return ['info', 'schedule'];
    case 'AUCTION_CUTOFF_PASSED':
      return ['warning', 'error_outline'];
    case 'AUCTION_WINNER_SELECTED':
      return ['success', 'check_circle_outline'];
    case 'AUCTION_CANCELLED':
      return ['secondary', 'cancel'];
    default:
      return ['', ''];
  }
}
