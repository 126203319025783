import { currentUserInfoFetchFailed, currentUserInfoFetched } from '@/store/users/users.actions';
import { getCurrentLocale } from '@/store/sessions/sessions.selectors';
import { getTranslations } from '@/locales';
import { trackUserInMatomo } from '@/Matomo';
import type { ErrorResponse } from '@/models/Responses/ErrorResponse';
import type { RootState } from '@/store/app.state';
import type { Thunk } from '@/store/app.actions';
import type { User } from '@/models/User';

const getUnauthorizedPage = (): string => {
  const {
    sgwtServices: { unauthorizedPage },
  } = window.appConfigs;
  return unauthorizedPage;
};

const getMaintenancePage = (): string => {
  const {
    sgwtServices: { maintenancePage },
  } = window.appConfigs;
  return maintenancePage;
};

const getContactUsEmail = (): string => {
  const { contactUsEmail } = window.appConfigs;
  return contactUsEmail;
};

const getUnauthorizedRedirectUrl = (state: RootState): string => {
  const unauthorizedPage = getUnauthorizedPage();
  const lang = encodeURIComponent(getCurrentLocale(state));
  const origin = encodeURIComponent(window.location.origin);
  return `${unauthorizedPage}?redirectUri=${origin}&language=${lang}`;
};

const getMaintenanceRedirectUrl = (state: RootState): string => {
  const maintenancePage = getMaintenancePage();
  const locale = getCurrentLocale(state);
  const lang = encodeURIComponent(getCurrentLocale(state));
  const origin = encodeURIComponent(window.location.origin);
  const appName = encodeURIComponent(getTranslations(locale)['SG Markets Bidding']);
  const contactEmail = encodeURIComponent(getContactUsEmail());
  return `${maintenancePage}?redirectUri=${origin}&language=${lang}&application=${appName}&contactUs=${contactEmail}`;
};

export const currentUserInfoFetchedThunk =
  (user: User): Thunk<void> =>
  dispatch => {
    dispatch(currentUserInfoFetched(user));
    trackUserInMatomo(user);
  };

export const currentUserInfoFetchFailedThunk =
  (error: ErrorResponse): Thunk<void> =>
  (dispatch, getState) => {
    dispatch(currentUserInfoFetchFailed(error));

    const state = getState();
    const errorRedirectUrl =
      error.status === 401 || error.status === 403
        ? getUnauthorizedRedirectUrl(state)
        : getMaintenanceRedirectUrl(state);
    window.location.href = errorRedirectUrl;
  };
