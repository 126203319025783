import { BlockBuilder } from '@/components/BlockBuilder';
import { Card, CardBody, CardHeader, CardTitle } from '@/components/shared/Card';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

interface DealersListBlockProps {
  className?: string;
}

export const DealersListBlock: FC<DealersListBlockProps> = ({ className }) => {
  return (
    <Card className={classNames('card-bordered pb-0', className)}>
      <CardHeader>
        <CardTitle>
          <FormattedMessage id="Dealers list" />
        </CardTitle>
      </CardHeader>
      <CardBody className="container-fluid">
        <BlockBuilder blockId="DealersList" isResponsive={false} />
      </CardBody>
    </Card>
  );
};
