import './App.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { AppIntlProvider } from '@/contexts/AppIntlProvider';
import { AppRouter } from '@/routes';
import { AppThemeProvider } from '@/contexts/ThemeProvider';
import { Provider } from 'react-redux';
import { SgmeServiceLoader } from '@/components/SgmeServiceLoader';
import { SgwtWidgetsProvider } from '@/contexts/SgwtWidgets/SgwtWidgetsProvider';
import { store } from '@/store';

export function App() {
  return (
    <Provider store={store}>
      <AppIntlProvider>
        <SgmeServiceLoader>
          <SgwtWidgetsProvider>
            <AppThemeProvider>
              <AppRouter />
            </AppThemeProvider>
          </SgwtWidgetsProvider>
        </SgmeServiceLoader>
      </AppIntlProvider>
    </Provider>
  );
}
