import { createSelector } from 'reselect';
import { getTimezoneOffsetFromTimeZoneId } from '@/utils/date';
import { getUiState } from '@/store/ui';
import { hasPermission } from '@/store/users';
import { isEmpty, isNotDefined } from '@sgme/fp';
import { parseISO } from 'date-fns';
import { pick } from 'lodash';
import type { Auction, BlockId, FieldId, Sales, ValidationError } from '@/models';
import type { AuctionKind, AuctionsState } from '@/store/auctions/auctions.model';
import type { RootState } from '@/store/app.state';
import type { SgmeError } from '@/models/Responses/ErrorResponse';

export const getAuctionsStatus =
  <T extends AuctionKind>(kind: T) =>
  (state: RootState): AuctionsState[T] =>
    state.auctions[kind];

export const getBlotterAuctions = createSelector([getAuctionsStatus('current')], blotter => {
  const { isLoading, blotterItems, error } = blotter;
  return { isLoading, error, auctions: blotterItems?.map(({ auction }) => auction) };
});

export const getBlotterAuctionByUuid = (auctionUuid: string | undefined) =>
  createSelector([getAuctionsStatus('current')], blotter => {
    const { blotterItems } = blotter;
    return blotterItems?.find(a => a.auction.auctionUuid === auctionUuid);
  });

export const getRfqDealerFromAuctionsBlotter = (auctionId?: string, dealerId?: string) => (state: RootState) => {
  const { blotterItems } = getAuctionsStatus('current')(state);

  if (isEmpty(auctionId) || isEmpty(dealerId) || isNotDefined(blotterItems) || isEmpty(blotterItems)) {
    return undefined;
  }
  const auction = blotterItems.find(item => item.auction.auctionUuid === auctionId)?.auction;
  const rfqDealers = auction?.rfqDealers;

  if (isNotDefined(rfqDealers) || isEmpty(rfqDealers)) {
    return undefined;
  }
  return rfqDealers.find(dealer => dealer.dealerRfqId === dealerId);
};

export const getBlockFieldIds = (blockId: BlockId) => (state: RootState) => {
  const strategyFormResult = getStrategyFormResult(state);
  return strategyFormResult?.blocksMetadata?.[blockId]?.children ?? [];
};

export const getFieldsMetadata = (blockId: BlockId) => (state: RootState) => {
  const strategyFormResult = getStrategyFormResult(state);
  if (strategyFormResult === undefined) {
    return undefined;
  }

  const fieldIds = getBlockFieldIds(blockId)(state);
  const fieldsMetadata = strategyFormResult.fieldsMetadata ?? {};
  return pick(fieldsMetadata, fieldIds);
};

export const getStrategyFormValue = (state: RootState) => {
  return getStrategyFormResult(state)?.values;
};

export const getPendingEnrichmentValue = (state: RootState) => {
  return getPendingEnrichment(state)?.values;
};

export const getPendingEnrichment = (state: RootState) => {
  const { pendingEnrichment } = getAuctionsStatus('enrich')(state);
  return pendingEnrichment;
};

export const getStrategyFormResult = (state: RootState) => {
  const { strategyFormResult } = getAuctionsStatus('enrich')(state);
  return strategyFormResult;
};

export function getStrategyFormStaticValues(state: RootState) {
  const staticFieldId: FieldId[] = ['DefaultDealers'];

  const values = getStrategyFormValue(state);
  const staticValueTuple = staticFieldId.map(id => [id, values?.[id]]);
  return Object.fromEntries(staticValueTuple);
}

export function getPendingEnrichmentStaticValues(state: RootState) {
  const staticFieldId: FieldId[] = ['DefaultDealers'];

  const values = getPendingEnrichmentValue(state);
  const staticValueTuple = staticFieldId.map(id => [id, values?.[id]]);
  return Object.fromEntries(staticValueTuple);
}

export const isEnriching = (state: RootState) => {
  const { isLoading } = getAuctionsStatus('enrich')(state);
  return isLoading;
};

export const getValidationErrors =
  (page: 'create' | 'edit') =>
  (state: RootState): ValidationError[] | undefined => {
    const { error } = getAuctionsStatus(page)(state);
    return error?.status === 400 ? error.response?.errors : undefined;
  };

export const getGenericErrorMessage =
  (page: 'create' | 'edit') =>
  (state: RootState): SgmeError | undefined => {
    const { error } = getAuctionsStatus(page)(state);
    return error?.status !== 400 ? error?.response : undefined;
  };

export function getSalesInitTimeZoneId(state: RootState): string | undefined {
  const { strategyFormResult } = getAuctionsStatus('enrich')(state);
  const sales = strategyFormResult?.values['SalesInit'] as Sales;
  return sales?.timeZoneId;
}

export function getAuctionRequestPayload(state: RootState, page: 'create' | 'edit') {
  const payload = getUiState(page)(state);
  const timeZoneId = getSalesInitTimeZoneId(state);

  if (!timeZoneId) {
    return {
      StartTime: undefined,
      EndTime: undefined,
      PositionConfirmTime: undefined,
      PreNotificationTime: undefined,
      ...payload,
    };
  }

  const timezoneOffset = getTimezoneOffsetFromTimeZoneId(timeZoneId);

  return {
    ...payload,
    StartTime: convertToUTC(timezoneOffset, payload.StartTime),
    EndTime: convertToUTC(timezoneOffset, payload.EndTime),
    PositionConfirmTime: convertToUTC(timezoneOffset, payload.PositionConfirmTime),
    PreNotificationTime: convertToUTC(timezoneOffset, payload.PreNotificationTime),
  };
}

export const getSelectedAuctionDealerIdForExecution = (auctionId?: string) => (state: RootState) => {
  const { auctions } = getAuctionsStatus('execute')(state);

  if (isNotDefined(auctions) || isEmpty(auctionId)) {
    return undefined;
  }
  const auctionExecutionState = auctions?.[auctionId];
  return auctionExecutionState?.dealerId;
};

function convertToUTC(timezoneOffset: number, dateTime?: string) {
  if (!dateTime) {
    return undefined;
  }
  return new Date(
    parseISO(dateTime).getTime() - new Date().getTimezoneOffset() * 60 * 1000 - timezoneOffset,
  ).toISOString();
}

export const getAuctionExecutionConfirmation = (state: RootState) => {
  const { confirmation } = getAuctionsStatus('execute')(state);
  return confirmation;
};
export const getAuctionExecutionError = (state: RootState) => {
  const { error } = getAuctionsStatus('execute')(state);
  return error;
};

export const isExecuting = (state: RootState) => {
  const { isLoading } = getAuctionsStatus('execute')(state);
  return isLoading;
};

export const getIsButtonDisabled =
  (name: string) =>
  (state: RootState): boolean =>
    state.auctions['enrich']?.strategyFormResult?.actions?.find(a => a.name === name)?.isDisabled ?? false;

export const canEditAuctionDetails = (auction: Auction) => (state: RootState) => {
  const hasRequiredPermission = hasPermission('READ_AUCTION_DETAILS')(state);

  if (auction === undefined) {
    return false;
  }

  const { workflowStatus } = auction;

  return (
    hasRequiredPermission &&
    (workflowStatus === 'AUCTION_CREATED' ||
      workflowStatus === 'AUCTION_PDC_T_MINUS_1_COMPLETED' ||
      workflowStatus === 'AUCTION_PDC_COMPLETED')
  );
};

export const canCancelAuctionDetails = (auction: Auction) => (state: RootState) => {
  const hasRequiredPermission = hasPermission('CANCEL_AUCTION')(state);

  if (auction === undefined) {
    return false;
  }

  const { workflowStatus } = auction;

  return (
    hasRequiredPermission &&
    (workflowStatus === 'AUCTION_CREATED' ||
      workflowStatus === 'AUCTION_PDC_T_MINUS_1_COMPLETED' ||
      workflowStatus === 'AUCTION_PDC_COMPLETED' ||
      workflowStatus === 'AUCTION_AWAITING_TO_START')
  );
};

export const getPdcDetails = (rfqId: string | undefined) => (state: RootState) => {
  if (rfqId === undefined) {
    return undefined;
  }

  const { pdcDetails } = getAuctionsStatus('current')(state);
  return pdcDetails?.find(pdc => pdc.uuid === rfqId);
};

export const getPdcDerogationReasons = () => (state: RootState) => {
  const { pdcDerogation } = getAuctionsStatus('current')(state);
  return pdcDerogation;
};
